import React, { Component } from "react";
//import { Bar, Doughnut } from 'react-chartjs-2';
//import DatePicker from "react-datepicker";
import axios from "axios";
//import { Form } from 'react-bootstrap';
// import "react-datepicker/dist/react-datepicker.css";
//import FormControl from "@material-ui/core/FormControl";
//import InputLabel from "@material-ui/core/InputLabel";
//import Input from "@material-ui/core/Input";


//import FormControl from "@material-ui/core/FormControl";
//import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
//import CustomInput from "components/CustomInput/CustomInput.js";
//import Button from "components/CustomButtons/Button.js";
require('dotenv').config();
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
//import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
export class AddEnquiry extends Component {
  state = {
    classes: [], academicYear: '', academicYears: [],
    firstName: '', lastName: '', fatherName: '', motherName: '', dateOfBirth: '', classId: '', mobileNo: '', email: '', address: '', classesList: [], gender: '', admissionDate: ''
  };
  
  constructor(props) {
    super(props)
    
  }

  componentDidMount() {
    let userId = localStorage.getItem('userId');
    this.setState({ userId: userId });
    console.log("userId:************************" + userId);
    this.getClasses(userId);
    this.getAcademicYear(userId);
  }

  getClasses = async (userId) => {

    try {
      let token = localStorage.getItem("token");
      //let pageNo = this.state.pageNo + 1;
      const headers = { 'Authorization': `Bearer ${token}` };
      let urlData = "&userId=" + userId;
      let classData = await axios.get(process.env.REACT_APP_APIURL + 'student/getClass?' + urlData, { headers });
      if (classData.data.code == 200) {
        //console.log("classData.data" + JSON.stringify(classData.data));
        //let staffData = staffData.data.data;
        this.setState({ classes: classData.data.data });

      } else {
        this.setState({ error: classData.data.message });
      }
    } catch (err) {
      console.log("error:" + JSON.stringify(err.response));
      this.setState({ isOtpSent: 'N' });
      this.setState({ error: err.response.data.message });
    }

    this.setState({ isLoading: false });

  }
  getAcademicYear = async (userId) => {

    try {
      let token = localStorage.getItem("token");
      //let pageNo = this.state.pageNo + 1;
      const headers = { 'Authorization': `Bearer ${token}` };
      let urlData = "&userId=" + userId;
      let academicYearData = await axios.get(process.env.REACT_APP_APIURL + 'student/getAcademicYear?' + urlData, { headers });
      if (academicYearData.data.code == 200) {
        this.setState({ academicYears: academicYearData.data.data });

      } else {
        //this.setState({ error: result.data.message });
      }
    } catch (err) {
      console.log("error:" + JSON.stringify(err.response));
      this.setState({ isOtpSent: 'N' });
      this.setState({ error: err.response.data.message });
    }

    this.setState({ isLoading: false });

  }
  setFirstName = (firstName) => {
    //console.log("firstName:"+firstName.target.value)
    this.setState({ firstName: firstName.target.value });
  }

  setLastName = (lastName) => {
    this.setState({ lastName: lastName.target.value });
  }

  setFatherName = (fatherName) => {
    this.setState({ fatherName: fatherName.target.value });
  }
  setMotherName = (motherName) => {
    this.setState({ motherName: motherName.target.value });
  }

  setAcademicYear = (academicYear) => {
    this.setState({ academicYear: academicYear.target.value });
  }
  setDateOfBirth = (dateOfBirth) => {
    this.setState({ dateOfBirth: dateOfBirth.target.value });
  }

  setSelectedClass = (classId) => {
    this.setState({ classId: classId.target.value });
  }
  setMobileNo = (mobileNo) => {
    this.setState({ mobileNo: mobileNo.target.value });
  }
  setEmail = (email) => {
    this.setState({ email: email.target.value });
  }

  setAddress = (address) => {
    this.setState({ address: address.target.value });
  }
  setGender = (gender) => {

    this.setState({ gender: gender.target.value });
  }
  setAdmissionDate = (admissionDate) => {
    this.setState({ admissionDate: admissionDate.target.value });
  }

  addStudent = async () => {
    console.log("addStudent");

    let token = localStorage.getItem("token");

    const headers = { 'Authorization': `Bearer ${token}` };

    if (this.state.userId == "" || this.state.userId == null) {
      this.setState({ error: "Something went wrong please try after sometime" })
      return false;
    }
    //console.log("this.state.firstName:"+this.state.firstName)
    if (this.state.firstName == "" || this.state.firstName == null) {
      this.setState({ error: "Please Enter fisrt name" })
      return false;
    }
    if (this.state.lastName == "" || this.state.lastName == null) {
      this.setState({ error: "Please enter Last Name" })
      return false;
    }
    if (this.state.gender == "" || this.state.gender == null) {
      this.setState({ error: "Please select gender" })
      return false;
    }

    if (this.state.fatherName == "" || this.state.fatherName == null) {
      this.setState({ error: "Please enter father Name" })
      return false;
    }
    if (this.state.motherName == "" || this.state.motherName == null) {
      this.setState({ error: "Please enter mother Name" })
      return false;
    }

    if (this.state.academicYear == "" || this.state.academicYear == null) {
      this.setState({ error: "Please enter academic Year" })
      return false;
    }
    if (this.state.dateOfBirth == "" || this.state.dateOfBirth == null) {
      this.setState({ error: "Please select Date of Birth" })
      return false;
    }
    if (this.state.classId == "" || this.state.classId == null) {
      this.setState({ error: "Please select Class" })
      return false;
    }

    if (this.state.mobileNo == "" || this.state.mobileNo == null) {
      this.setState({ error: "Please enter mobile No." })
      return false;
    }
    if (this.state.address == "" || this.state.address == null) {
      this.setState({ error: "Please enter Address" })
      return false;
    }
    const data = {
      userId: this.state.userId,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      gender: this.state.gender,
      fatherName: this.state.fatherName,
      motherName: this.state.motherName,
      dateOfBirth: this.state.dateOfBirth,
      mobileNo: this.state.mobileNo,
      classId: this.state.classId,
      email: this.state.email,
      address: this.state.address,
      academicYear: this.state.academicYear,
    };

    
    const result = await axios.post(process.env.REACT_APP_APIURL + "student/addEnquiry", data, { headers });
    
    if (result.code == 401) {
      console.log("LogOut");
      this.setState({ error: result.error });
    }
    if (result.data.code == 200) {
      this.setState({ firstName: '' });
      this.setState({ lastName: '' });
      this.setState({ gender: '' });
      this.setState({ fatherName: '' });
      this.setState({ motherName: '' });
      this.setState({ dateOfBirth: '' });
      this.setState({ mobileNo: '' });
      this.setState({ classId: '' });
      this.setState({ email: '' });
      this.setState({ address: '' });
      this.setState({ academicYear: '' });
      this.setState({ error: result.data.message });

    } else {

      this.setState({ error: result.data.message })
    }
    this.setState({ isLoading: false });
  }
  render() {
    return (
      <div>
        <Card>
            
            <CardBody>
              <GridContainer>    
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel id="demo-simple-select-helper-label">Select Academic Year</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.academicYear}
                    style={{width:'100%'}}
                    onChange={this.setAcademicYear}
                  >
                    <MenuItem  value=''>Select Academic Year</MenuItem>
                    {
                          this.state.academicYears.map((object, i) => {
                            return <MenuItem key={i} value={object['academicYear']}>{object['academicYear']}</MenuItem>
                          })
                    }
                    
                  </Select>
                  
                </GridItem>
                
              </GridContainer>
              <GridContainer>
                <div>{this.state.error}</div>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="First Name"
                    id="firstName"
                    value={this.state.firstName}
                    onChange={this.setFirstName}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>                
                  <CustomInput
                    labelText="Last Name"
                    id="lastName"
                    value={this.state.lastName}
                    onChange={this.setLastName}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                
                <GridItem xs={12} sm={12} md={6}>                
                  <CustomInput
                    labelText="Father Name"
                    id="fatherName"
                    value={this.state.fatherName}
                    onChange={this.setFatherName}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>                
                  <CustomInput
                    labelText="Mother Name"
                    id="motherName"
                    value={this.state.motherName}
                    onChange={this.setMotherName}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>                
                  <CustomInput
                    labelText="Birth Date"
                    type="date"
                    id="dateOfBirth"
                    value={this.state.dateOfBirth}
                    onChange={this.setDateOfBirth}
                    formControlProps={{
                      fullWidth: true,
                      type:'date'
                    }}
                  />
                </GridItem>
                 
                <GridItem xs={12} sm={12} md={6}>                
                  <CustomInput
                    labelText="Address"
                    id="address"
                    value={this.state.address}
                    onChange={this.setAddress}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>

              </GridContainer>
              <GridContainer>             
        
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel id="demo-simple-select-helper-label">Gender</InputLabel>
                  <Select xs={12} sm={12} md={12}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.gender}
                    onChange={this.setGender}
                    style={{width:'100%'}}
                    className="col-md-12"
                  >
                    <MenuItem value={''}>Gender</MenuItem>
                    <MenuItem value={'girl'}>Girl</MenuItem>
                    <MenuItem value={'boy'}>Boy</MenuItem>
                  </Select>
                  
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel id="demo-simple-select-helper-label">Select Class</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.classId}
                    style={{width:'100%'}}
                    onChange={this.setSelectedClass}
                  >
                    <MenuItem  value=''>Select Class</MenuItem>
                    {
                          this.state.classes.map((object, i) => {
                            return <MenuItem key={i} value={object['id']}>{object['className']}</MenuItem>
                          })
                    }
                  </Select>
                  
                </GridItem>
                
              </GridContainer>
              
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Mobile No"
                    id="mobileNo"
                    value={this.state.mobileNo}
                    onChange={this.setMobileNo}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>                
                  <CustomInput
                    labelText="Email"
                    id="email"
                    value={this.state.email}
                    onChange={this.setEmail}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                
              </GridContainer>
              <GridContainer>
                
                
                
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="primary" className="btn btn-gradient-primary mr-2"  onClick={this.addStudent.bind(this)}>Save</Button>
            </CardFooter>
          </Card>
        

      </div>
    );
  }
}
export default AddEnquiry;