
import React, { Component } from 'react';
// react plugin for creating charts
// @material-ui/core
import { makeStyles,withStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
//import CurrencyRupeeIcon from '@material-ui/icons/CurrencyRupee';
import { Accessibility, DateRange,Warning ,Store,LocalOffer,Update,ArrowUpward,AccessTime,BugReport,Code,Cloud} 
from '@material-ui/icons';

// core components
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Table from 'react-bootstrap/Table'

import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import moment from 'moment';
export class Attendance extends Component {
  state = { attendanceDate: '', userId: '', classes: [], classId: '', academicYear: '', studentList: [],error:'',mobileNo:'' }
  constructor(props) {
    super(props)
    console.log(props)
  }


  componentDidMount() {
    let userId = localStorage.getItem('userId');
    let mobileNo=localStorage.getItem('mobileNo');
    let attendanceDate = moment(new Date()).format("YYYY-MM-DD");
    this.setState({ attendanceDate: attendanceDate });
    this.setState({ mobileNo: mobileNo });
    this.setState({ userId: userId });
    this.getClasses(userId);
    this.getAcademicYear(userId);
    this.setState({error:''});
  }
  setSelectedClass = (classId) => {
    this.setState({ classId: classId.target.value });
    this.getStudentAttendanceByClass(classId.target.value)
  }
  getClasses = async (userId) => {

    try {
      let token = localStorage.getItem("token");
      let pageNo = this.state.pageNo + 1;
      const headers = { 'Authorization': `Bearer ${token}` };
      let urlData = "&userId=" + userId;
      let classData = await axios.get(process.env.REACT_APP_APIURL + 'student/getClass?' + urlData, { headers });
      if (classData.data.code == 200) {
        
        //let staffData = staffData.data.data;
        this.setState({ classes: classData.data.data });

      } else {
        this.setState({ error: classData.data.message });
      }
    } catch (err) {
      
      this.setState({ isOtpSent: 'N' });
      this.setState({ error: err.response.data.message });
    }

    this.setState({ isLoading: false });

  }
  getAcademicYear = async (userId) => {

    try {
      let token = localStorage.getItem("token");
      let pageNo = this.state.pageNo + 1;
      const headers = { 'Authorization': `Bearer ${token}` };
      let urlData = "&userId=" + userId;
      let academicYearData = await axios.get(process.env.REACT_APP_APIURL + 'student/getCurrentAcademicYear?' + urlData, { headers });
      if (academicYearData.data.code == 200) {
        
        this.setState({ academicYear: academicYearData.data.data.academicYear });

      } else {
        //this.setState({ error: result.data.message });
      }
    } catch (err) {
      
      this.setState({ isOtpSent: 'N' });
      this.setState({ error: err.response.data.message });
    }

    this.setState({ isLoading: false });

  }
  getStudentAttendanceByClass = async (classId) => {

    try {
      let token = localStorage.getItem("token");
      if (this.state.academicYear == "") {
        this.setState({ error: "Please select academic year" })
        return false;
      }

      const headers = { 'Authorization': `Bearer ${token}` };
      let urlData = "&userId=" + this.state.userId + "&classId=" + classId + "&academicYear=" + this.state.academicYear;
      let studentData = await axios.get(process.env.REACT_APP_APIURL + 'student/getStudentAttendanceByClass?' + urlData, { headers });
      if (studentData.data.code == 200) {
        

        this.setState({ studentList: studentData.data.data });

      } else {
        //this.setState({ error: result.data.message });
      }
    } catch (err) {
      
      this.setState({ isOtpSent: 'N' });
      this.setState({ error: err.response.data.message });
    }

    this.setState({ isLoading: false });

  }
  addAttendance=async(item,status)=>{
    
    try {
      
      let token = localStorage.getItem("token");
      console.log("item:"+JSON.stringify(item));
      item.status=status;
      console.log("After item:"+JSON.stringify(item));
      this.setState({ isLoading: true });
      
      let studentId=item.id;
      let classId=item.classId;
      if (status == "") {
        this.setState({ error: "Something went wrong" })
        return false;
      }
      const data = {
        userId: this.state.userId,
        studentId:studentId,
        classId: classId,
        status: status
      };
      const headers = { 'Authorization': `Bearer ${token}` };
      
      let studentData = await axios.post(process.env.REACT_APP_APIURL + 'student/addAttendance?', data, { headers });
      if (studentData.data.code == 200) {
        
        this.setState({ error: studentData.data.message });
        //this.getStudentFeesByClass(classId);
      } else {
        this.setState({ error: studentData.data.message });
      }
    } catch (err) {
      
      this.setState({ isOtpSent: 'N' });
      this.setState({ error: err.response.data.message });
    }

    this.setState({ isLoading: false });
  }
  sendAttendanceReport(){
   /*try {
    console.log("ok")
    let absentStudents='';
    for(let i=0;i<this.state.studentList.length;++i){
      console.log("in loop="+JSON.stringify(this.state.studentList[i]));
      absentStudents=absentStudents+" "+this.state.studentList[i]['studentName'];
    }
    let msg="Following Students are absent today: "+absentStudents;
    let webUrl="https://wa.me/"+this.state.mobileNo+"?text="+msg;
    //console.log("Msg:"+webUrl);
    window.open(webUrl, "_blank", "WhatsApp");
   } catch (error) {
    
   } */
  }
  render() {
    return (
      <div> 
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="warning">
                <h4 className={{color:'white'}}>Student Attendance</h4>               
              </CardHeader>
              <CardBody>
              <div style={{ width: '100%' }}>   
                <div><select className="form-control" value={this.state.classId} onChange={this.setSelectedClass}>
                      <option value={''}>Select Class</option>
                      {
                        this.state.classes.map((object, i) => {
                          return <option key={i} value={object['id']}>{object['className']}</option>
                        })
                      }
                    </select>
                </div>             
                <Table stripped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th> Student Name </th>
                      <th> Mobile </th>
                      <th> Status </th>
                      <th> Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                        this.state.studentList.map((object, i) => {
                          return <tr key={i + 1}>
                            <td>{i + 1}</td>
                            <td>{object.studentName}</td>
                            <td>{object.mobileNo} </td>
                            <td>{object.status} </td>
                            <td>
                              <Button type="button" onClick={this.addAttendance.bind(this,object,'present')} className="btn btn-gradient-success">Present</Button>
                              <Button type="button"  onClick={this.addAttendance.bind(this,object,'absent')} className="btn btn-gradient-danger">Absent</Button>
                            </td>
                          </tr>
                        })
                      }
                
                  </tbody>
                </Table>
              </div>
              </CardBody>
            </Card>
          </GridItem>          
      </div>
      /*<div>
        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Student Attendance</h4>
                <div className='error'>{this.state.error}</div>
                <Form.Group className="row">
                  <div>    <button type="button" onClick={this.sendAttendanceReport.bind(this)} className="btn btn-gradient-success">Absent Report</button></div>
                  <label className="col-sm-12 col-form-label">Class</label>
                  <div className="col-sm-12">
                    <select className="form-control" value={this.state.classId} onChange={this.setSelectedClass}>
                      <option value={''}>Select Class</option>
                      {
                        this.state.classes.map((object, i) => {
                          return <option key={i} value={object['id']}>{object['className']}</option>
                        })
                      }
                    </select>
                  </div>
                </Form.Group>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Sr.</th>
                        <th> Student Name </th>
                        <th> Mobile </th>
                        <th> Status </th>
                        <th> Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.studentList.map((object, i) => {
                          return <tr key={i + 1}>
                            <td>{i + 1}</td>
                            <td>{object.studentName}</td>
                            <td>{object.mobileNo} </td>
                            <td>{object.status} </td>
                            <td>
                              <button type="button" onClick={this.addAttendance.bind(this,object,'present')} className="btn btn-gradient-success">Present</button>
                              <button type="button"  onClick={this.addAttendance.bind(this,object,'absent')} className="btn btn-gradient-danger">Absent</button>
                            </td>
                          </tr>
                        })
                      }
                      
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>*/
    );
  }
}
export default Attendance;