import React, { Component } from 'react';

import axios from "axios";
import { Form } from 'react-bootstrap';
import Button from "components/CustomButtons/Button.js";

const dotenv = require('dotenv');

export class AddStudentFees extends Component {
  state = {
    userId: '', error: '', userName: '', classId: '',studentId: '', selectedClass: 0, feesId: 0, classList: [],studentList:[],
    admitionFees: 0, tutionFees: 0, dressFees: 0, bookFees: 0, cabFees: 0, libraryFees: 0, other: 0, classData: {},academicYear:'',totalFees:0,paidFees:0,pendingFees:0
  };
  constructor(props) {
    super(props);

    //console.log("process.env.REACT_APP_APIURL=" + process.env.REACT_APP_APIURL);
  }
  setSelectedClass = (classId) => {
    console.log("studentName.target.value" + classId.target.value);
    this.setState({ classId: classId.target.value });
    //this.viewFees(classId.target.value);
    this.getStudent(classId.target.value);
  }
  setStudentId=(studentId)=>{    
    this.setState({ studentId: studentId.target.value });
    this.getStudentFeesById(studentId.target.value)
  }
  setAdmitionFees = (admitionFees) => {
    console.log("studentName.target.value" + admitionFees.target.value);
    this.setState({ admitionFees: admitionFees.target.value });
  }
  setLibraryFees = (libraryFees) => {
    console.log("libraryFees" + libraryFees.target.value);
    this.setState({ libraryFees: libraryFees.target.value });
  }
  setTutionFees = (tutionFees) => {
    this.setState({ tutionFees: tutionFees.target.value });
  }
  setDressFees = (dressFees) => {
    this.setState({ dressFees: dressFees.target.value });
  }
  setBookFees = (bookFees) => {
    this.setState({ bookFees: bookFees.target.value });
  }
  setCabFees = (cabFees) => {
    this.setState({ cabFees: cabFees.target.value });
  }
  setOther = (other) => {
    this.setState({ other: other.target.value });
  }

  componentDidMount() {
    let userId = localStorage.getItem('userId');
    this.setState({ userId: userId });
    this.getClasses(userId); 
  }
  getClasses = async (userId) => {

    try {
      let token = localStorage.getItem("token");
      let pageNo = this.state.pageNo + 1;
      const headers = { 'Authorization': `Bearer ${token}` };
      let urlData = "&userId=" + userId;
      let classData = await axios.get(process.env.REACT_APP_APIURL + 'student/getClass?' + urlData, { headers });
      if (classData.data.code == 200) {
        //console.log("classData.data" + JSON.stringify(classData.data));
        //let staffData = staffData.data.data;
        this.setState({ classList: classData.data.data });

      } else {
        this.setState({ error: classData.data.message });
      }
    } catch (err) {
      console.log("error:" + JSON.stringify(err.response));
      this.setState({ isOtpSent: 'N' });
      this.setState({ error: err.response.data.message });
    }

    this.setState({ isLoading: false });

  }
  getStudent = async (classId) => {
    try {
      let token = localStorage.getItem("token");
      let pageNo = this.state.pageNo + 1;
      const headers = { 'Authorization': `Bearer ${token}` };
      let urlData = "&userId=" + this.state.userId+"&classId="+classId;
      let studentData = await axios.get(process.env.REACT_APP_APIURL + 'student/getStudentByClass?' + urlData, { headers });
      if (studentData.data.code == 200) {
        //console.log("studentData.data" + JSON.stringify(studentData.data));
        //let staffData = staffData.data.data;
        this.setState({ studentList: studentData.data.data });

      } else {
        this.setState({ error: studentData.data.message });
      }
    } catch (err) {
      console.log("error:" + JSON.stringify(err.response));
      this.setState({ isOtpSent: 'N' });
      this.setState({ error: err.response.data.message });
    }

    this.setState({ isLoading: false });

  }
  
  getStudentFeesById = async (studentId) => {
    try {
      let token = localStorage.getItem("token");
      
      const headers = { 'Authorization': `Bearer ${token}` };
      let urlData = "&userId=" + this.state.userId+"&classId="+this.state.classId+"&studentId="+studentId;
      let studentData = await axios.get(process.env.REACT_APP_APIURL + 'fees/getFeesStudentById?' + urlData, { headers });
      if (studentData.data.code == 200) {
        //console.log("studentData.data" + JSON.stringify(studentData.data));
        let student = studentData.data.data;
        //this.setState({ studentList: studentData.data.data });
        this.setState({ feesId: student.id });
        this.setState({ classId: student.classId });
        this.setState({ studentId: student.studentId });
        this.setState({ admitionFees: student.admissionFees });
        this.setState({ tutionFees: student.tutionFees });
        this.setState({ dressFees: student.dressFees });
        this.setState({ bookFees: student.bookFees });
        this.setState({ cabFees: student.cabFees });
        this.setState({ libraryFees: student.libraryFees });
        this.setState({ other: student.other });
        this.setState({ totalFees: student.total });
        this.setState({ paidFees: student.paid });
        this.setState({ pendingFees: student.pending });
        

      } else {
        this.setState({ error: studentData.data.message });
      }
    } catch (err) {
      console.log("error:" + JSON.stringify(err.response));
      this.setState({ isOtpSent: 'N' });
      this.setState({ error: err.response.data.message });
    }

    this.setState({ isLoading: false });

  }
  addStudentFees = async () => {
    console.log("addStudentFees");
    let token = localStorage.getItem("token");
    console.log("UserId:" + this.state.userId)
    const headers = { 'Authorization': `Bearer ${token}` };
    let carModelNo = '';
    let carNo = '';
    if (this.state.userId == "" || this.state.userId == null) {
      this.setState({ error: "Something went wrong please try after sometime" })
      return false;
    }
    if (this.state.classId == "" || this.state.classId == null) {
      this.setState({ error: "Please select class" })
      return false;
    }
    if (this.state.studentId == "" || this.state.studentId == null) {
      this.setState({ error: "Please select student" })
      return false;
    }
    
    if (isNaN(this.state.admitionFees)) {
      this.setState({ error: "Please enter admition Fees" })
      return false;
    }
    if (isNaN(this.state.tutionFees)) {
      this.setState({ error: "Please enter tution Fees" })
      return false;
    }
    if (isNaN(this.state.dressFees)) {
      this.setState({ error: "Please enter dress Fees" })
      return false;
    }
    if (isNaN(this.state.bookFees)) {
      this.setState({ error: "Please enter book Fees" })
      return false;
    }
    if (isNaN(this.state.cabFees)) {
      this.setState({ error: "Please enter Cab Fees" })
      return false;
    }
    if (isNaN(this.state.libraryFees)) {
      this.setState({ error: "Please enter Library Fees" })
      return false;
    }
    if (isNaN(this.state.other)) {
      this.setState({ error: "Please enter other Fees" })
      return false;
    }
    const data = {
      userId: this.state.userId,
      classId: this.state.classId,
      studentId: this.state.studentId,
      admissionFees: this.state.admitionFees,
      tutionFees: this.state.tutionFees,
      dressFees: this.state.dressFees,
      bookFees: this.state.bookFees,
      cabFees: this.state.cabFees,
      libraryFees: this.state.libraryFees,
      other: this.state.other,
      academicYear:this.state.academicYear
    };

    //console.log("=======" + data)
    const result = await axios.post(process.env.REACT_APP_APIURL + "fees/addStudentFees", data, { headers });
    console.log("result" + result);
    if (result.code == 401) {
      console.log("LogOut");
      
    }
    if (result.data.code == 200) {
      this.setState({ error: result.data.message });
      this.setState({ error: result.error });
      this.setState({ classId: '' });
      this.setState({ studentId: '' });
      this.setState({ admitionFees: '' });
      this.setState({ tutionFees: '' });
      this.setState({ dressFees: '' });
      this.setState({ bookFees: '' });
      this.setState({ cabFees: '' });
      this.setState({ libraryFees: '' });
      this.setState({ other: '' });

    } else {

      this.setState({ error: result.data.message })
    }
    this.setState({ isLoading: false });
  }
  editStudentFees = async () => {
    try {

      let token = localStorage.getItem("token");
      console.log("UserId:" + this.state.userId)
      const headers = { 'Authorization': `Bearer ${token}` };
      let carModelNo = '';
      let carNo = '';
      if (this.state.userId == "" || this.state.userId == null) {
        this.setState({ error: "Something went wrong please try after sometime" })
        return false;
      }
      if (this.state.feesId == "" || this.state.feesId == null) {
        this.setState({ error: "Something went wrong please try after sometime" })
        return false;
      }
      if (this.state.classId == "" || this.state.classId == null) {
        this.setState({ error: "Please select class" })
        return false;
      }
      if (this.state.studentId == "" || this.state.studentId == null) {
        this.setState({ error: "Please select student" })
        return false;
      }
      console.log(this.state.studentId+"this.state.admitionFees:"+this.state.admitionFees)
      if (isNaN(this.state.admitionFees) || this.state.studentId == "") {
        this.setState({ error: "Please enter admission Fees" })
        return false;
      }
      
      if (isNaN(this.state.tutionFees)) {
        this.setState({ error: "Please enter tution Fees" })
        return false;
      }
      if (isNaN(this.state.dressFees)) {
        this.setState({ error: "Please enter dress Fees" })
        return false;
      }
      if (isNaN(this.state.bookFees)) {
        this.setState({ error: "Please enter book Fees" })
        return false;
      }
      if (isNaN(this.state.cabFees)) {
        this.setState({ error: "Please enter Cab Fees" })
        return false;
      }
      if (isNaN(this.state.libraryFees)) {
        this.setState({ error: "Please enter Library Fees" })
        return false;
      }
      if (isNaN(this.state.other)) {
        this.setState({ error: "Please enter other Fees" })
        return false;
      }
      const data = {
        userId: this.state.userId,
        feesId: this.state.feesId,
        classId: this.state.classId,
        studentId: this.state.studentId,
        admissionFees: this.state.admitionFees,
        tutionFees: this.state.tutionFees,
        dressFees: this.state.dressFees,
        bookFees: this.state.bookFees,
        cabFees: this.state.cabFees,
        libraryFees: this.state.libraryFees,
        other: this.state.other,
      };
      console.log("=======" + data)
      const result = await axios.put(process.env.REACT_APP_APIURL + "fees/updateStudentFees", data, { headers });
      console.log("result" + result);
      if (result.code == 401) {
        console.log("LogOut");
        this.setState({ error: result.error });
      }
      if (result.data.code == 200) {
        this.setState({ error: result.data.message });

      } else {

        this.setState({ error: result.data.message })
      }
      this.setState({ isLoading: false });
    } catch (err) {

      this.setState({ isOtpSent: 'N' });
      this.setState({ error: err.response.data.message });
    }

    this.setState({ isLoading: false });
  }
  viewFees = async (classId) => {

    try {
      let token = localStorage.getItem("token");
      let pageNo = this.state.pageNo + 1;
      const headers = { 'Authorization': `Bearer ${token}` };
      let urlData = "&userId=" + this.state.userId + "&classId=" + classId;
      let cabData = await axios.get(process.env.REACT_APP_APIURL + 'fees/getFeesByClass?' + urlData, { headers });
      if (cabData.data.code == 200) {
        console.log("cabData.data" + JSON.stringify(cabData.data));
        let classData = cabData.data.data;
        this.setState({ classData: cabData.data.data });
        this.setState({ feesId: classData.id });
        this.setState({ selectedClass: classData.classId });
        this.setState({ admitionFees: classData.admissionFees });
        this.setState({ tutionFees: classData.tutionFees });
        this.setState({ dressFees: classData.dressFees });
        this.setState({ bookFees: classData.bookFees });
        this.setState({ cabFees: classData.cabFees });
        this.setState({ libraryFees: classData.libraryFees });
        this.setState({ other: classData.other });


      } else {
        //this.setState({ error: result.data.message });
      }
    } catch (err) {
      console.log("error:" + JSON.stringify(err.response));
      this.setState({ isOtpSent: 'N' });
      this.setState({ error: err.response.data.message });
    }

    this.setState({ isLoading: false });

  }
  render() {
    return (
      <div>
        

        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Add/Update Student Fees</h4>
                <div className='error'>{this.state.error}</div>
                <form className="forms-sample">
                  <Form.Group className="row">
                    <label className="col-sm-12 col-form-label">Class</label>
                    <div className="col-sm-12">
                      <select className="form-control" value={this.state.classId} onChange={this.setSelectedClass}>
                        <option value={''}>Select Class</option>
                        {
                          this.state.classList.map((object, i) => {
                            return <option key={i} value={object['id']}>{object['className']}</option>
                          })
                        }
                      </select>
                    </div>
                  </Form.Group>
                  <Form.Group className="row">
                    <label className="col-sm-12 col-form-label">Students</label>
                    <div className="col-sm-12">
                      <select className="form-control" value={this.state.studentId} onChange={this.setStudentId}>
                        <option value={''}>Select Student</option>
                        {
                          this.state.studentList.map((object, i) => {
                            return <option key={i} value={object['id']}>{object['firstName']} {object['lastName']}</option>
                          })
                        }
                      </select>
                    </div>
                  </Form.Group>
                  {
                    this.state.feesId>0?<div className='btn-gradient-primary' style={{display:'flex',flexDirection:"row",justifyContent:'space-between',paddingBottom:10,paddingTop:10,paddingLeft:10,paddingRight:10,marginBottom:10}}>
                        <div>Total Fees:{this.state.totalFees}</div>
                        <div>Paid Fees:{this.state.paidFees}</div>
                        <div>Remaining Fees:{this.state.pendingFees}</div>
                    </div>:null
                  }
                  <Form.Group>
                    <label htmlFor="exampleInputUsername1">Admission Fees</label>
                    <Form.Control type="text" id="exampleInputUsername1" value={this.state.admitionFees} placeholder="Admission Fees" size="lg" onChange={this.setAdmitionFees} />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputEmail1">Tution Fees</label>
                    <Form.Control type="text" className="form-control" id="" value={this.state.tutionFees} placeholder="Tution Fees" onChange={this.setTutionFees} />
                  </Form.Group>

                  <Form.Group>
                    <label htmlFor="exampleInputEmail1">Dress Fees</label>
                    <Form.Control type="text" className="form-control" id="exampleInputEmail1" placeholder="Dress Fees" value={this.state.dressFees} onChange={this.setDressFees} />
                  </Form.Group>


                  <Form.Group>
                    <label htmlFor="exampleInputEmail1">Book Fees</label>
                    <Form.Control type="text" className="form-control" value={this.state.bookFees} id="exampleInputEmail1" placeholder="Book Fees" onChange={this.setBookFees} />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputEmail1">Library Fees</label>
                    <Form.Control type="text" className="form-control" value={this.state.libraryFees} id="exampleInputEmail1" placeholder="Library Fees" onChange={this.setLibraryFees} />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputEmail1">Cab Fees</label>
                    <Form.Control type="text" className="form-control" value={this.state.cabFees} id="exampleInputEmail1" placeholder="Cab Fees" onChange={this.setCabFees} />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputEmail1">Other</label>
                    <Form.Control type="text" className="form-control" value={this.state.other} id="exampleInputEmail1" placeholder="Other" onChange={this.setOther} />
                  </Form.Group>
                  {
                    this.state.feesId > 0 ? <Button color="primary" title="Send Fees Reminder" className="btn btn-gradient-primary mr-2"  onClick={this.editStudentFees.bind(this)}>Update</Button> : <Button color="primary" title="Send Fees Reminder" className="btn btn-gradient-primary mr-2"  onClick={this.addStudentFees.bind(this)}>Save</Button>
                  }
                </form>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}
export default AddStudentFees;