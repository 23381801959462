import React, { Component } from 'react';

import axios from "axios";

import Button from "components/CustomButtons/Button.js";
import AlertBox from "components/Alert/Alert.js";

import { BugReport,Delete,WhatsApp} from '@material-ui/icons';
import Modal from 'react-bootstrap/Modal';
export class ViewStudent extends Component {
  state = {
    studentList: [], userId: '', error: '', classes: [],showAlert:false,variant:'success',
    showConfirmation:false,deleteStudentId:0
  }
  constructor(props) {
    super(props)
    this.child = React.createRef()
  }

  componentDidMount() {
    //your code
    let userId = localStorage.getItem('userId');
    this.setState({ userId: userId });
    //console.log("userId:************************" + userId);
    this.getClasses(userId);
    
  }
  getClasses = async (userId) => {

    try {
      let token = localStorage.getItem("token");
      let pageNo = this.state.pageNo + 1;
      const headers = { 'Authorization': `Bearer ${token}` };
      let urlData = "&userId=" + userId;
      let classData = await axios.get(process.env.REACT_APP_APIURL + 'student/getClass?' + urlData, { headers });
      if (classData.data.code == 200) {
        
        this.setState({ classes: classData.data.data });

      } else {
        this.child.current.handleClickFromParent(true,'danger',classData.data.message)
        //this.setState({ error: classData.data.message });
      }
    } catch (err) {
      console.log("error:" + JSON.stringify(err.response));
      this.child.current.handleClickFromParent(true,'danger',err.response.data.message)
      //this.setState({ error: err.response.data.message });
    }

    this.setState({ isLoading: false });

  }
  getStudentByClass = async (classId) => {

    try {
      let token = localStorage.getItem("token");
      let pageNo = this.state.pageNo + 1;
      const headers = { 'Authorization': `Bearer ${token}` };
      let urlData = "&userId=" + this.state.userId + "&classId=" + classId;
      //console.log("urlData:"+urlData)
      let studentData = await axios.get(process.env.REACT_APP_APIURL + 'student/getStudentByClass?' + urlData, { headers });
      //console.log("studentData:"+JSON.stringify(studentData));
      if (studentData.data.code == 200) {
        
        this.setState({ studentList: studentData.data.data });

      } else {
        //this.setState({ error: result.data.message });
      }
    } catch (err) {
      console.log("error:" + JSON.stringify(err.response));
      this.child.current.handleClickFromParent(true,'danger',err.response.data.message)
      //this.setState({ error: err.response.data.message });
    }

    this.setState({ isLoading: false });

  }
  setSelectedClass = (event) => {
    let classId = event.target.value;
    this.getStudentByClass(classId);
  }
  deleteStudent=async()=>{
    //console.log("object:"+JSON.stringify(object));
    //this.child.handleClickFromParent();
    
    try {
      this.setState({showConfirmation:false})
      let studentId=this.state.deleteStudentId;
      let token = localStorage.getItem("token");
      let pageNo = this.state.pageNo + 1;
      const headers = { 'Authorization': `Bearer ${token}` };
      let urlData = "&userId=" + this.state.userId+"&studentId=" + studentId;
      console.log(urlData);
      
      let classData = await axios.delete(process.env.REACT_APP_APIURL + 'student/deleteStudent?' + urlData, { headers });
      if (classData.data.code == 401) {
        console.log("LogOut");
        //this.setState({ error: classData.data.message });
        this.child.current.handleClickFromParent(true,'danger',classData.data.message)
      }
      
      if (classData.data.code == 200) {
        console.log("classData.data" + JSON.stringify(classData.data));
        this.child.current.handleClickFromParent(true,'success',classData.data.message)
      } else {
        //this.setState({ error: classData.data.message });
        this.child.current.handleClickFromParent(true,'danger',classData.data.message)
      }
    } catch (err) {
      console.log("e:" + JSON.stringify(err.response));
      this.child.current.handleClickFromParent(true,'danger',err.response.data.message)
      //this.setState({ error: err.response.data.message });
    }
  }
  handleClose=()=>{
    this.setState({showConfirmation:false})
  }
  showConfirmationPop=(object)=>{
    console.log("object:"+JSON.stringify(object));
    this.setState({deleteStudentId:object.id,showConfirmation:true})
  }
  render() {
    return (
      <div>
        {/*<div className="proBanner">
          <div>
            <span className="d-flex align-items-center purchase-popup">
              <p>Get tons of UI components, Plugins, multiple layouts, 20+ sample pages, and more!</p>
              <a href="https://www.bootstrapdash.com/product/purple-react/?utm_source=organic&utm_medium=banner&utm_campaign=free-preview" rel="noopener noreferrer" target="_blank" className="btn btn-sm purchase-button ml-auto">Check Pro Version</a>
              <i className="mdi mdi-close bannerClose" onClick={this.toggleProBanner}></i>
            </span>
          </div>
    </div>*/}
        <AlertBox ref={this.child} ></AlertBox>
        <Modal show={this.state.showConfirmation} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this student</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              No
            </Button>
            <Button variant="primary" onClick={this.deleteStudent}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Student Details</h4>
                <div>
                  
                  <select className="form-control" value={this.state.classId} onChange={this.setSelectedClass}>
                    <option value={''}>Select Class</option>
                    {
                      this.state.classes.map((object, i) => {
                        return <option key={i} value={object['id']}>{object['className']}</option>
                      })
                    }
                  </select>
                </div>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Sr</th>
                        <th> Student Name </th>
                        <th> Father Name </th>
                        <th> Mother Name </th>
                        <th>Gender</th>
                        <th>dateOfBirth</th>
                        <th> Class </th>
                        <th> Mobile </th>
                        <th> Address</th>
                        <th>Academic Year</th>
                        <th>dateOfAdmission</th>
                        <th>#</th>
                      </tr>
                    </thead>
                    <tbody>
                      {                        
                        this.state.studentList.map((object, i) => {
                          return <tr key={i + 1}>
                            <td>{i+1}</td>
                            <td>{object.firstName} {object.lastName}</td>
                            <td>{object.fatherName}</td>
                            <td>{object.motherName}</td>
                            <td>{object.gender}</td>
                            <td>{object.dateOfBirth}</td>
                            <td>{object.classId}</td>
                            <td>{object.mobileNo}</td>
                            <td>{object.address}</td>
                            <td>{object.academicYear}</td>
                            <td>{object.dateOfAdmission}</td>
                            <td><Button color="primary" title="Delete Student" className="btn btn-gradient-primary mr-2"  onClick={this.showConfirmationPop.bind(this, object)}><Delete></Delete></Button></td>
                            </tr>
                        })
                      }
                    
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default ViewStudent;