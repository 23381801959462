import React, { Component } from 'react';
import axios from "axios";
import { Form } from 'react-bootstrap';

import Button from "components/CustomButtons/Button.js";
// import "react-datepicker/dist/react-datepicker.css";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { makeStyles,withStyles } from "@material-ui/core/styles";
//import style from '../../assets/css/style'
const dotenv = require('dotenv');
const useStyles = makeStyles(styles);
export class UpdateGradeStudent extends Component {
    state = {
        userId: '', error: '', classId: '', studentId: '', studentName: '', className: '', academicYear: '', totalFees: 0, paidFees: 0, pendingFees: 0, amount: 0,
        collectionDate: '', classes: [], academicYears: [], nextAcademicYear: '', nextClass: '', leftReason: '', upgrade: ''
    };
    constructor(props) {
        super(props);

    }

    componentDidMount() {
        console.log("Gello............")
        let userId = localStorage.getItem('userId');
        this.setState({ userId: userId });
        let studentId = this.props.match.params.studentId;
        let classId = this.props.match.params.classId;
        this.getClasses(userId);
        this.getAcademicYear(userId);
        this.getStudentFeesById(studentId, classId, userId);
    }
    getClasses = async (userId) => {

        try {
            let token = localStorage.getItem("token");
            let pageNo = this.state.pageNo + 1;
            const headers = { 'Authorization': `Bearer ${token}` };
            let urlData = "&userId=" + userId;
            let classData = await axios.get(process.env.REACT_APP_APIURL + 'student/getClass?' + urlData, { headers });
            if (classData.data.code == 200) {
                console.log("classData.data" + JSON.stringify(classData.data));
                //let staffData = staffData.data.data;
                this.setState({ classes: classData.data.data });

            } else {
                this.setState({ error: classData.data.message });
            }
        } catch (err) {
            console.log("error:" + JSON.stringify(err.response));
            this.setState({ isOtpSent: 'N' });
            this.setState({ error: err.response.data.message });
        }

        this.setState({ isLoading: false });

    }
    getAcademicYear = async (userId) => {

        try {
            let token = localStorage.getItem("token");
            let pageNo = this.state.pageNo + 1;
            const headers = { 'Authorization': `Bearer ${token}` };
            let urlData = "&userId=" + userId;
            let academicYearData = await axios.get(process.env.REACT_APP_APIURL + 'student/getAcademicYear?' + urlData, { headers });
            if (academicYearData.data.code == 200) {
                this.setState({ academicYears: academicYearData.data.data });

            } else {
                //this.setState({ error: result.data.message });
            }
        } catch (err) {
            console.log("error:" + JSON.stringify(err.response));
            this.setState({ isOtpSent: 'N' });
            this.setState({ error: err.response.data.message });
        }

        this.setState({ isLoading: false });

    }
    getStudentFeesById = async (studentId, classId, userId) => {
        try {
            let token = localStorage.getItem("token");
            const headers = { 'Authorization': `Bearer ${token}` };
            let urlData = "&userId=" + userId + "&classId=" + classId + "&studentId=" + studentId;
            let studentData = await axios.get(process.env.REACT_APP_APIURL + 'fees/getFeesStudentById?' + urlData, { headers });
            if (studentData.data.code == 200) {
                console.log("studentData.data" + JSON.stringify(studentData.data));
                let student = studentData.data.data;
                //this.setState({ studentList: studentData.data.data });
                this.setState({ classId: student.classId });
                this.setState({ studentId: student.studentId });
                this.setState({ studentName: student.studentName });
                this.setState({ className: student.className });
                this.setState({ academicYear: student.academicYear });
                this.setState({ totalFees: student.total });
                this.setState({ paidFees: student.paid });
                this.setState({ pendingFees: student.pending });
            } else {
                this.setState({ error: studentData.data.message });
            }
        } catch (err) {
            console.log("error:" + JSON.stringify(err.response));
            this.setState({ isOtpSent: 'N' });
            this.setState({ error: err.response.data.message });
        }
        this.setState({ isLoading: false });

    }
    setNextAcademicYear = (nextAcademicYear) => {
        this.setState({ nextAcademicYear: nextAcademicYear.target.value });
    }

    setNextClass = (nextClass) => {
        this.setState({ nextClass: nextClass.target.value });
    }
    setLeftReason = (leftReason) => {
        this.setState({ leftReason: leftReason.target.value });
    }
    setUpgrade = (upgrade) => {
        this.setState({ upgrade: upgrade.target.value });
    }
    upgradNow = async () => {
        try {
            console.log("addStudentFees");
            let token = localStorage.getItem("token");
            console.log("UserId:" + this.state.userId)
            const headers = { 'Authorization': `Bearer ${token}` };
            
            if(this.state.upgrade==''){
                this.setState({ error: "Please select upgrade reason" })
                return false;
            }
            if(this.state.upgrade=='pass'){                
                
                if (this.state.nextAcademicYear == "" || this.state.nextAcademicYear == null) {
                    this.setState({ error: "Please select next acadmic year" })
                    return false;
                }
                if (this.state.nextClass == "" || this.state.nextClass == null) {
                    this.setState({ error: "Please select next year class" })
                    return false;
                }
                
                if (this.state.classId == this.state.nextClass) {
                    this.setState({ error: "You can't upgrade to same class" });
                    return false;
                }
            }
            if(this.state.upgrade=='left'){
                if (this.state.leftReason == "" || this.state.leftReason == null) {
                    this.setState({ error: "Please enter left reason" })
                    return false;
                }
            }
            
            const data = {
                userId: this.state.userId,
                classId: this.state.classId,
                studentId: this.state.studentId,
                nextClass: this.state.nextClass,
                nextAcademicYear: this.state.nextAcademicYear,
                upgradeReason: this.state.upgrade,
                leftReason: this.state.leftReason
            };

            const result = await axios.post(process.env.REACT_APP_APIURL + "student/upgradStudent", data, { headers });
            console.log("result" + result);
            if (result.code == 401) {
                console.log("LogOut");
            }
            if (result.data.code == 200) {
                this.setState({ error: result.data.message });
                this.setState({ upgrade: '' });
                this.setState({ leftReason: '' });
                window.location.href="/admin/student/ViewStudent";                

            } else {
                this.setState({ error: result.data.message })
            }
        } catch (err) {
            console.log("error:" + JSON.stringify(err.response));
            this.setState({ isOtpSent: 'N' });
            this.setState({ error: err.response.data.message });
        }

        this.setState({ isLoading: false });

    }
    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Upgrade Student</h4>
                                <div className='error'>{this.state.error}</div>
                                <form className="forms-sample">

                                    <Form.Group style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label style={{ flex: 0.3 }}>Student Name :</label>
                                        <label style={{ flex: 0.7 }}>{this.state.studentName}</label>
                                    </Form.Group>
                                    <Form.Group style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label style={{ flex: 0.3 }}>Class :</label>
                                        <label style={{ flex: 0.7 }}>{this.state.className}</label>
                                    </Form.Group>
                                    <Form.Group style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label style={{ flex: 0.3 }}>Total Fees :</label>
                                        <label style={{ flex: 0.7 }}>{this.state.totalFees}</label>
                                    </Form.Group>
                                    <Form.Group style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label style={{ flex: 0.3 }}>Paid Fees :</label>
                                        <label style={{ flex: 0.7 }}>{this.state.paidFees}</label>
                                    </Form.Group>
                                    <Form.Group style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label style={{ flex: 0.3 }}>Remaining Fees :</label>
                                        <label style={{ flex: 0.7 }}>{this.state.pendingFees}</label>
                                    </Form.Group>

                                    <Form.Group className="row">
                                        <label className="col-sm-12 col-form-label">Student Upgrade</label>
                                        <div className="col-sm-12">
                                            <select className="form-control" value={this.state.upgrade} onChange={this.setUpgrade}>
                                                <option value={''}>Select Upgrade Reason</option>
                                                <option value={'pass'}>Move To Next Class</option>
                                                <option value={'left'}>Leave The School</option>

                                            </select>
                                        </div>
                                    </Form.Group>
                                    {
                                        this.state.upgrade == 'left' ? <Form.Group>
                                            <label htmlFor="exampleInputEmail1">Reason</label>
                                            <Form.Control type="text" className="form-control" value={this.state.leftReason} id="leftReason" placeholder="Reason for left" onChange={this.setLeftReason} />
                                        </Form.Group> : null
                                    }

                                    {
                                        this.state.upgrade == 'pass' ? <div><Form.Group className="row">
                                            <label className="col-sm-12 col-form-label">Upgrad to Class</label>
                                            <div className="col-sm-12">
                                                <select className="form-control" value={this.state.nextClass} onChange={this.setNextClass}>
                                                    <option value={''}>Select Class</option>
                                                    {
                                                        this.state.classes.map((object, i) => {
                                                            return <option key={i} value={object['id']}>{object['className']}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </Form.Group>
                                            <Form.Group className="row">
                                                <label className="col-sm-12 col-form-label">Academic Year</label>
                                                <div className="col-sm-12">
                                                    <select className="form-control" value={this.state.nextAcademicYear} onChange={this.setNextAcademicYear}>
                                                        <option value={''}>Select Academic Year</option>
                                                        {
                                                            this.state.academicYears.map((object, i) => {
                                                                return <option key={i} value={object['academicYear']}>{object['academicYear']}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </Form.Group> </div> : null
                                    }
                                    <br/>

                                    {
                                        this.state.upgrade !='' ? <button type="button" className="btn btn-primary mr-2" onClick={this.upgradNow.bind(this)}>Update</button> : null
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}
export default withStyles(useStyles) (UpdateGradeStudent);;