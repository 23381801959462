import React, { Component } from 'react';

import axios from "axios";
import { Form } from 'react-bootstrap';

const dotenv = require('dotenv');

export class FeesPayNow extends Component {
    state = {
        userId: '', error: '', classId: '', studentId: '', studentName: '', className: '', academicYear: '', totalFees: 0, paidFees: 0, pendingFees: 0, amount: 0,
        collectionDate: ''
    };
    constructor(props) {
        super(props);

    }

    componentDidMount() {
        let userId = localStorage.getItem('userId');
        this.setState({ userId: userId });
        let studentId = this.props.match.params.studentId;
        let classId = this.props.match.params.classId;
        
        this.setState({ studentId: studentId });
        this.setState({ classId: classId });
        this.getStudentFeesById(studentId, classId, userId);
    }
    getStudentFeesById = async (studentId, classId, userId) => {
        try {
            let token = localStorage.getItem("token");

            const headers = { 'Authorization': `Bearer ${token}` };
            let urlData = "&userId=" + userId + "&classId=" + classId + "&studentId=" + studentId;
            let studentData = await axios.get(process.env.REACT_APP_APIURL + 'fees/getFeesStudentById?' + urlData, { headers });
            if (studentData.data.code == 200) {
                console.log("studentData.data" + JSON.stringify(studentData.data));
                let student = studentData.data.data;
                //this.setState({ studentList: studentData.data.data });
                this.setState({ classId: student.classId });
                this.setState({ studentId: student.studentId });
                this.setState({ studentName: student.studentName });
                this.setState({ className: student.className });
                this.setState({ academicYear: student.academicYear });
                this.setState({ totalFees: student.total });
                this.setState({ paidFees: student.paid });
                this.setState({ pendingFees: student.pending });
            } else {
                this.setState({ error: studentData.data.message });
            }
        } catch (err) {
            console.log("error:" + JSON.stringify(err.response));
            this.setState({ isOtpSent: 'N' });
            this.setState({ error: err.response.data.message });
        }
        this.setState({ isLoading: false });

    }
    setAmount = (amount) => {
        this.setState({ amount: amount.target.value });
    }

    setCollectionDate = (collectionDate) => {
        this.setState({ collectionDate: collectionDate.target.value });
    }
    payNow = async () => {
        try {
            console.log("addStudentFees");
            let token = localStorage.getItem("token");
            console.log("UserId:" + this.state.userId)
            const headers = { 'Authorization': `Bearer ${token}` };
            if (this.state.amount == "" || this.state.amount <= 0) {
                this.setState({ error: "Please enter amount" });
                return false;
            }
            if (this.state.collectionDate == "" || this.state.collectionDate == null) {
                this.setState({ error: "Please select date" })
                return false;
            }
            const data = {
                userId: this.state.userId,
                classId: this.state.classId,
                studentId: this.state.studentId,
                amount: this.state.amount,
                collectionDate:this.state.collectionDate
            };

            const result = await axios.post(process.env.REACT_APP_APIURL + "fees/collectStudentFees", data, { headers });
            console.log("result" + result);
            if (result.code == 401) {
                console.log("LogOut");
            }
            if (result.data.code == 200) {
                this.setState({ error: result.data.message });
                this.setState({ amount: '' });
                this.setState({ collectionDate: '' });
                this.getStudentFeesById(this.state.studentId, this.state.classId, this.state.userId);

            } else {

                this.setState({ error: result.data.message })
            }
        } catch (err) {
            console.log("error:" + JSON.stringify(err.response));
            this.setState({ isOtpSent: 'N' });
            this.setState({ error: err.response.data.message });
        }

        this.setState({ isLoading: false });

    }
    render() {
        return (
            <div>


                <div className="row">
                    <div className="col-md-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Fees Collection</h4>
                                <div className='error'>{this.state.error}</div>
                                <form className="forms-sample">

                                    <Form.Group style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label style={{ flex: 0.3 }}>Student Name :</label>
                                        <label style={{ flex: 0.7 }}>{this.state.studentName}</label>
                                    </Form.Group>
                                    <Form.Group style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label style={{ flex: 0.3 }}>Class :</label>
                                        <label style={{ flex: 0.7 }}>{this.state.className}</label>
                                    </Form.Group>
                                    <Form.Group style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label style={{ flex: 0.3 }}>Total Fees :</label>
                                        <label style={{ flex: 0.7 }}>{this.state.totalFees}</label>
                                    </Form.Group>
                                    <Form.Group style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label style={{ flex: 0.3 }}>Paid Fees :</label>
                                        <label style={{ flex: 0.7 }}>{this.state.paidFees}</label>
                                    </Form.Group>
                                    <Form.Group style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label style={{ flex: 0.3 }}>Remaining Fees :</label>
                                        <label style={{ flex: 0.7 }}>{this.state.pendingFees}</label>
                                    </Form.Group>
                                    <Form.Group>
                                        <label htmlFor="exampleInputEmail1">Amount Pay Now</label>
                                        <Form.Control type="text" className="form-control" value={this.state.amount} id="amount" placeholder="Amount" onChange={this.setAmount} />
                                    </Form.Group>
                                    <Form.Group>
                                        <label htmlFor="dateOfBirth">Collection Date</label>
                                        <Form.Control type="date" id="collectionDate" value={this.state.collectionDate} placeholder="Collection Date" size="lg" onChange={this.setCollectionDate} />
                                    </Form.Group>
                                    {
                                        this.state.pendingFees > 0 ? <button type="button" className="btn btn-gradient-primary mr-2" onClick={this.payNow.bind(this)}>Pay Now</button> : null
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}
export default FeesPayNow;