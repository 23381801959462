import React, { Component } from 'react';

import axios from "axios";
import { Form } from 'react-bootstrap';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
const dotenv = require('dotenv');
import Button from "components/CustomButtons/Button.js";

export class StudentFeesDetails extends Component {
    state = {
        userId: '', error: '', classId: '', studentId: '', studentName: '', className: '', academicYear: '', totalFees: 0, paidFees: 0, pendingFees: 0, amount: 0,
        collectionDate: '',studentList: []
    };
    constructor(props) {
        super(props);

    }

    componentDidMount() {
        let userId = localStorage.getItem('userId');
        this.setState({ userId: userId });
        let studentId = this.props.match.params.studentId;
        let classId = this.props.match.params.classId;
        
        this.setState({ studentId: studentId });
        this.setState({ classId: classId });
        this.getCollectionDetails(userId,studentId, classId);
    }
    getCollectionDetails = async (userId,studentId,classId) => {

        try {
          let token = localStorage.getItem("token");
          
    
          const headers = { 'Authorization': `Bearer ${token}` };
          let urlData = "&userId=" + this.state.userId + "&classId=" + classId + "&studentId=" + studentId;
          let studentData = await axios.get(process.env.REACT_APP_APIURL + 'report/getCollectionDetails?' + urlData, { headers });
          if (studentData.data.code == 200) {
            console.log("studentData.data" + JSON.stringify(studentData.data));
    
            this.setState({ studentList: studentData.data.data });
    
          } else {
            //this.setState({ error: result.data.message });
          }
        } catch (err) {
          console.log("error:" + JSON.stringify(err.response));
          this.setState({ isOtpSent: 'N' });
          this.setState({ error: err.response.data.message });
        }
    
        this.setState({ isLoading: false });
    
    }
    
    render() {
        return (
            <div>

                <Card>
                    <CardHeader color="warning">
                        <h4>Fees Collection Details</h4>                            
                    </CardHeader>
                    <CardBody>
                        <div className="card-body">
                            <div className='error'>{this.state.error}</div>
                            <div className="table-responsive">
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>Sr</th>
                                    <th> Student Name </th>
                                    <th> Class </th>
                                    <th> Amount paid </th>
                                    <th> Date</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.studentList.map((object, i) => {
                                    return <tr key={i + 1}>
                                        <td>{i + 1}</td>
                                        <td>{object.studentName}</td>
                                        <td>{object.className}</td>
                                        <td>{object.collectionAmount}</td>
                                        <td>{object.collectionDate}</td>
                                    </tr>
                                    })
                                }

                                </tbody>
                            </table>
                            </div>
                            </div>        
                        </CardBody>
                    </Card>
                       
            </div>
        );
    }
}
export default StudentFeesDetails;