import React, { Component } from 'react';

import axios from "axios";
import { Form } from 'react-bootstrap';
// import "react-datepicker/dist/react-datepicker.css";
import Button from "components/CustomButtons/Button.js";


export class AddStaff extends Component {
  state = {userId:'',type:'',
    firstName: '',lastName:'',gender:'',mobileNo:'',staffPassword:'',email:'',address:'',education:'',experience:'',salary:'',joinDate:'',staffId:0
  };
  
  componentDidMount(){
    //your code   
    let userId = localStorage.getItem('userId');

    this.setState({ userId: userId });
    console.log("userId:************************" + userId); 
  }
  constructor(props){
    super(props)    
  }
  setFirstName=(firstName)=>{
    console.log("studentName.target.value"+firstName.target.value);
    this.setState({firstName:firstName.target.value});
  }
  setLastName=(lastName)=>{
    this.setState({lastName:lastName.target.value});
  }
  setGender=(gender)=>{
    this.setState({gender:gender.target.value});
  }
  setMobileNo=(mobileNo)=>{
    this.setState({mobileNo:mobileNo.target.value});
  }
  setStaffPassword=(staffPassword)=>{    
    this.setState({staffPassword:staffPassword.target.value});
  }
  setEmail=(email)=>{    
    this.setState({email:email.target.value});
  }
  setAddress=(address)=>{    
    this.setState({address:address.target.value});
  }
  setEducation=(education)=>{    
    this.setState({education:education.target.value});
  }
  setExperience=(experience)=>{    
    this.setState({experience:experience.target.value});
  }
  setSalary=(salary)=>{    
    this.setState({salary:salary.target.value});
  }
  setJoinDate=(joinDate)=>{    
    this.setState({joinDate:joinDate.target.value});
  }
  setType=(type)=>{    
    this.setState({type:type.target.value});
  }
  
  addStaff = async () => {
    console.log("addStaff");
    
    let token = localStorage.getItem("token");
    
    const headers = { 'Authorization': `Bearer ${token}` };
    let carModelNo = '';
    let carNo = '';
    if (this.state.userId == "" || this.state.userId == null) {
      this.setState({ error: "Something went wrong please try after sometime" })
      return false;
    }
    if (this.state.firstName == "" || this.state.firstName == null) {
      this.setState({ error: "Please Enter fisrt name" })
      return false;
    }
    if (this.state.lastName == "" || this.state.lastName == null) {
      this.setState({ error: "Please enter Last Name" })
      return false;
    }
    if (this.state.gender == "" || this.state.gender == null) {
      this.setState({ error: "Please select gender" })
      return false;
    }
    if (this.state.type == "" || this.state.type == null) {
      this.setState({ error: "Please select type" })
      return false;
    }
    
    if (this.state.mobileNo == "" || this.state.mobileNo == null) {
      this.setState({ error: "Please enter mobile No." })
      return false;
    }
    if (this.state.staffPassword == "" || this.state.staffPassword == null) {
      this.setState({ error: "Please enter password" })
      return false;
    }
    if (this.state.email == "" || this.state.email == null) {
      this.setState({ error: "Please enter email" })
      return false;
    }
    if (this.state.address == "" || this.state.address == null) {
      this.setState({ error: "Please enter Address" })
      return false;
    }
    if (this.state.education == "" || this.state.education == null) {
      this.setState({ error: "Please enter Education" })
      return false;
    }
    if (this.state.experience == "" || this.state.experience == null) {
      this.setState({ error: "Please enter Experience" })
      return false;
    }
    if (this.state.salary == "" || this.state.salary == null || this.state.salary<=0) {
      this.setState({ error: "Please enter salary" })
      return false;
    }
    if (this.state.joinDate == "" || this.state.joinDate == null) {
      this.setState({ error: "Please enter joinDate" })
      return false;
    }
    const data = {
      userId: this.state.userId,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      gender: this.state.gender,
      type:this.state.type,
      mobileNo: this.state.mobileNo,
      staffPassword: this.state.staffPassword,
      email: this.state.email,
      address: this.state.address,
      education: this.state.education,
      experience: this.state.experience,
      salary: this.state.salary,
      joinDate: this.state.joinDate,
    };

    console.log("=======" + data)
    const result = await axios.post(process.env.REACT_APP_APIURL + "staff/addStaff", data, { headers });
    console.log("result" + result);
    if (result.code == 401) {
      console.log("LogOut");
      this.setState({ error: result.error });
    }
    if (result.data.code == 200) {
      this.setState({ error: result.data.message });
      this.setState({ firstName: '' });
      this.setState({ lastName: '' });
      this.setState({ gender: '' });
      this.setState({ type: '' });
      this.setState({ mobileNo: '' });
      this.setState({ staffPassword: '' });
      this.setState({ email: '' });
      this.setState({ address: '' });
      this.setState({ education: '' });
      this.setState({ experience: '' });
      this.setState({ salary: '' });
      this.setState({ joinDate: '' });


    } else {

      this.setState({ error: result.data.message })
    }
    this.setState({ isLoading: false });
  }
  editStaff = async () => {
    try {

      let token = localStorage.getItem("token");
      console.log("UserId:" + this.state.userId)
      const headers = { 'Authorization': `Bearer ${token}` };
      let carModelNo = '';
      let carNo = '';
      if (this.state.userId == "" || this.state.userId == null) {
        this.setState({ error: "Something went wrong please try after sometime" })
        return false;
      }
      if (this.state.feesId == "" || this.state.feesId == null) {
        this.setState({ error: "Something went wrong please try after sometime" })
        return false;
      }
      if (this.state.classId == "" || this.state.classId == null) {
        this.setState({ error: "Please select class" })
        return false;
      }
      if (this.state.admitionFees == "" || this.state.admitionFees == null) {
        this.setState({ error: "Please enter admition Fees" })
        return false;
      }
      if (this.state.tutionFees == "" || this.state.tutionFees == null) {
        this.setState({ error: "Please enter tution Fees" })
        return false;
      }
      if (this.state.dressFees == "" || this.state.dressFees == null) {
        this.setState({ error: "Please enter dress Fees" })
        return false;
      }
      if (this.state.bookFees == "" || this.state.bookFees == null) {
        this.setState({ error: "Please enter book Fees" })
        return false;
      }
      if (this.state.cabFees == "" || this.state.cabFees == null) {
        this.setState({ error: "Please enter Cab Fees" })
        return false;
      }
      if (this.state.libraryFees == "" || this.state.libraryFees == null) {
        this.setState({ error: "Please enter Library Fees" })
        return false;
      }
      if (this.state.other == "" || this.state.other == null) {
        this.setState({ error: "Please enter other Fees" })
        return false;
      }
      const data = {
        userId: this.state.userId,
        feesId:this.state.feesId,
        classId: this.state.classId,
        admissionFees: this.state.admitionFees,
        tutionFees: this.state.tutionFees,
        dressFees: this.state.dressFees,
        bookFees: this.state.bookFees,
        cabFees: this.state.cabFees,
        libraryFees: this.state.libraryFees,
        other: this.state.other,
      };
      console.log("=======" + data)
      const result = await axios.put(process.env.REACT_APP_APIURL + "fees/updateClassFees", data, { headers });
      console.log("result" + result);
      if (result.code == 401) {
        console.log("LogOut");
        this.setState({ error: result.error });
      }
      if (result.data.code == 200) {
        this.setState({ error: result.data.message });

      } else {

        this.setState({ error: result.data.message })
      }
      this.setState({ isLoading: false });
    } catch (err) {

      this.setState({ isOtpSent: 'N' });
      this.setState({ error: err.response.data.message });
    }

    this.setState({ isLoading: false });
  }
  render () {
    return (
      <div>        
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className='error'>{this.state.error}</div>
                <form className="forms-sample">
                  <Form.Group>
                    <label htmlFor="firstName">First Name</label>
                    <Form.Control type="text" id="firstName" value={this.state.firstName} placeholder="First Name" onChange={this.setFirstName} />
                  </Form.Group>                  
                  <Form.Group>
                    <label htmlFor="lastName">Last Name</label>
                    <Form.Control type="text" className="form-control" id="lastName" placeholder="Last Name" value={this.state.lastName} onChange={this.setLastName}/>
                  </Form.Group>
                  <Form.Group className="row">  
                        <label className="col-sm-12 col-form-label">Gender</label>
                        <div className="col-sm-12">
                          <select className="form-control" value={this.state.gender} onChange={this.setGender}>
                            <option value={''}>Select Gender</option>
                            <option value={'M'}>Male</option>
                            <option value={'F'}>Female</option>
                          </select>
                        </div>
                  </Form.Group>
                  <Form.Group className="row">  
                        <label className="col-sm-12 col-form-label">Type</label>
                        <div className="col-sm-12">
                          <select className="form-control" value={this.state.type} onChange={this.setType}>
                            <option value={''}>Select Type</option>
                            <option value={'admin'}>Admin</option>
                            <option value={'principal'}>Principal</option>
                            <option value={'teacher'}>Teacher</option>
                            <option value={'accountant'}>Accountant</option>
                            <option value={'staff'}>Staff</option>
                            <option value={'driver'}>Driver</option>
                          </select>
                        </div>
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="mobileNo">Mobile No</label>
                    <Form.Control type="text" className="form-control" value={this.state.mobileNo} id="mobileNo" placeholder="Mobile No" onChange={this.setMobileNo}/>
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="email">Email</label>
                    <Form.Control type="email" className="form-control" value={this.state.email} id="email" placeholder="Email" onChange={this.setEmail}/>
                  </Form.Group>
                  
                  <Form.Group>
                    <label htmlFor="staffPassword">staffPassword</label>
                    <Form.Control type="password" className="form-control" id="staffPassword" value={this.state.staffPassword} placeholder="Password" onChange={this.setStaffPassword} />
                  </Form.Group>
                  
                  <Form.Group>
                    <label htmlFor="address">address</label>
                    <Form.Control type="text" className="form-control" id="address" value={this.state.address} placeholder="Address" onChange={this.setAddress} />
                  </Form.Group>
                  
                  <Form.Group>
                    <label htmlFor="joinDate">Join Date</label>
                    <Form.Control type="date" className="form-control" id="joinDate" value={this.state.joinDate} placeholder="Join date" onChange={this.setJoinDate} />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="education">Education</label>
                    <Form.Control type="text" className="form-control" id="education" value={this.state.education} placeholder="Education" onChange={this.setEducation} />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="experience">Experience</label>
                    <Form.Control type="text" className="form-control" id="experience" value={this.state.experience} placeholder="Experience" onChange={this.setExperience} />
                  </Form.Group>
                  
                  <Form.Group>
                    <label htmlFor="salary">Salary</label>
                    <Form.Control type="email" className="form-control" id="salary" value={this.state.salary} placeholder="Salary" onChange={this.setSalary} />
                  </Form.Group>
                  <Form.Group>

                  
                  {
                    this.state.staffId > 0 ? <Button color="primary" className="btn btn-gradient-primary mr-2"  onClick={this.editStaff.bind(this)}>Update</Button> : <Button color="primary" className="btn btn-gradient-primary mr-2"  onClick={this.addStaff.bind(this)}>Save</Button>
                  }
                  </Form.Group>
                </form>
              </div>
            </div>
          </div>
        </div>
        
      </div> 
    );
  }
}
const ListItem = (props) => {
    
  return (
      <li className={(props.isCompleted ? 'completed' : null)}>
          <div className="form-check">
              <label htmlFor="" className="form-check-label"> 
                  <input className="checkbox" type="checkbox" 
                      checked={props.isCompleted} 
                      onChange={props.changed} 
                      /> {props.children} <i className="input-helper"></i>
              </label>
          </div>
          <i className="remove mdi mdi-close-circle-outline" onClick={props.remove}></i>
      </li>
  )
};
export default AddStaff;