
import React, { Component } from 'react';
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles,withStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
//import CurrencyRupeeIcon from '@material-ui/icons/CurrencyRupee';
import { Accessibility, DateRange,Warning ,Store,LocalOffer,Update,ArrowUpward,AccessTime,BugReport,Code,Cloud} 
from '@material-ui/icons';

import { Form } from 'react-bootstrap';
// core components
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Table from 'react-bootstrap/Table'

import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import moment from 'moment';
export class StudentAttendanceReport extends Component {
  state = { attendanceDate: '', userId: '', classes: [], classId: '', academicYear: '', studentList: [],
  error:'',mobileNo:'' }
  constructor(props) {
    super(props)
    //console.log(props)
  }


  componentDidMount() {
    let userId = localStorage.getItem('userId');
    let mobileNo=localStorage.getItem('mobileNo');
    let attendanceDate = moment(new Date()).format("YYYY-MM-DD");
    this.setState({ attendanceDate: attendanceDate });
    this.setState({ mobileNo: mobileNo });
    this.setState({ userId: userId });
    this.getClasses(userId);
    //this.getAcademicYear(userId);
    this.setState({error:''});
  }
  setSelectedClass = (classId) => {
    this.setState({ classId: classId.target.value });
    //this.getStudentAttendanceByClass(classId.target.value)
  }
  getClasses = async (userId) => {

    try {
      let token = localStorage.getItem("token");
      let pageNo = this.state.pageNo + 1;
      const headers = { 'Authorization': `Bearer ${token}` };
      let urlData = "&userId=" + userId;
      let classData = await axios.get(process.env.REACT_APP_APIURL + 'student/getClass?' + urlData, { headers });
      if (classData.data.code == 200) {        
        //let staffData = staffData.data.data;
        this.setState({ classes: classData.data.data });
      } else {
        this.setState({ error: classData.data.message });
      }
    } catch (err) {      
      this.setState({ isOtpSent: 'N' });
      this.setState({ error: err.response.data.message });
    }
    this.setState({ isLoading: false });

  }
  getAcademicYear = async (userId) => {

    try {
      let token = localStorage.getItem("token");
      let pageNo = this.state.pageNo + 1;
      const headers = { 'Authorization': `Bearer ${token}` };
      let urlData = "&userId=" + userId;
      let academicYearData = await axios.get(process.env.REACT_APP_APIURL + 'student/getCurrentAcademicYear?' + urlData, { headers });
      if (academicYearData.data.code == 200) {
        
        this.setState({ academicYear: academicYearData.data.data.academicYear });

      } else {
        //this.setState({ error: result.data.message });
      }
    } catch (err) {
      
      this.setState({ isOtpSent: 'N' });
      this.setState({ error: err.response.data.message });
    }

    this.setState({ isLoading: false });

  }
  getStudentAttendanceByClass = async () => {

    try {
      let token = localStorage.getItem("token");
      if(!this.state.classId){
        this.setState({ error:  "Please select Class"});
        return false;
      }
      if(!this.state.attendanceDate){
        this.setState({ error:  "Please select date"});
        return false;
      }
      this.setState({ error:  ""});
      const headers = { 'Authorization': `Bearer ${token}` };
      let day=""
      let urlData = "&userId=" + this.state.userId + "&classId=" + this.state.classId+ "&day=" + this.state.attendanceDate;
      let studentData = await axios.get(process.env.REACT_APP_APIURL + 'report/getStudentAttendanceReport?' + urlData, { headers });
      if (studentData.data.code == 200) {
        if(studentData.data.data){
          this.setState({ studentList: studentData.data.data });
        }else{
          this.setState({ error: studentData.data.message });
        }
        
      } else {
        this.setState({ error: studentData.data.message });
      }
    } catch (err) {
      
      this.setState({ isOtpSent: 'N' });
      this.setState({ error: err.response.data.message });
    }
    this.setState({ isLoading: false });

  }
  setAttendanceDate = (attendanceDate) => {
    this.setState({ attendanceDate: attendanceDate.target.value });
  }
  render() {
    return (
      <div> 
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="warning">
                <h4 className={{color:'white'}}>Student Attendance</h4>               
              </CardHeader>
              <CardBody>
              <div style={{ width: '100%' }}>   
                <div>{this.state.error}</div>
                <div><select className="form-control" value={this.state.classId} onChange={this.setSelectedClass}>
                      <option value={''}>Select Class</option>
                      {
                        this.state.classes.map((object, i) => {
                          return <option key={i} value={object['id']}>{object['className']}</option>
                        })
                      }
                    </select>
                </div>
                <Form.Group>
                  <label htmlFor="dateOfBirth">Date</label>
                  <Form.Control type="date" id="attendanceDate" style={{fontSize:12}} value={this.state.attendanceDate} placeholder="Attendance Date" size="lg" onChange={this.setAttendanceDate} />
                </Form.Group>     
                <div>
                <Button type="button" className="btn btn-gradient-success" onClick={this.getStudentAttendanceByClass.bind(this)}>Search</Button>
                </div>        
                <Table stripped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th> Student Name </th>
                      <th> attendanceDate </th>
                      <th> Status </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                        this.state.studentList.map((object, i) => {
                          return <tr key={i + 1}>
                            <td>{i + 1}</td>
                            <td>{object.studentName}</td>
                            <td>{object.attendanceDate} </td>
                            <td>{object.status} </td>
                            
                          </tr>
                        })
                      }
                
                  </tbody>
                </Table>
              </div>
              </CardBody>
            </Card>
          </GridItem>          
      </div>
      
    );
  }
}
export default StudentAttendanceReport;