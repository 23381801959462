import React, { Component } from 'react';
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles,withStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
//import CurrencyRupeeIcon from '@material-ui/icons/CurrencyRupee';
import { Accessibility, DateRange,Warning ,Store,LocalOffer,Update,ArrowUpward,AccessTime,BugReport,Code,Cloud} 
from '@material-ui/icons';

// core components
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Table from 'react-bootstrap/Table'

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
//import '/bootstrap/dist/css/bootstrap.css';
import axios from "axios";
import moment from 'moment';

require('dotenv').config()
//const classes = useStyles();
const useStyles = makeStyles(styles);
export class StaffAttendanceReport extends Component {
    state = { userId: '', staffData: [], error: '', attendanceDate: '' }
    constructor(props) {
      super(props);
    }
    componentDidMount() {
      let userId = localStorage.getItem('userId');
      let attendanceDate = moment(new Date()).format("YYYY-MM-DD");
      this.setState({ attendanceDate: attendanceDate });
      this.setState({ userId: userId });
      this.getStaff(userId, attendanceDate);
    }
  
    getStaff = async (userId, attendanceDate) => {
      try {
        let token = localStorage.getItem("token");
        const headers = { 'Authorization': `Bearer ${token}` };
        let urlData = "&userId=" + userId + "&attendanceDate=" + attendanceDate;
        let staffData = await axios.get(process.env.REACT_APP_APIURL + 'staff/getStaffAttendance?' + urlData, { headers });
        if (staffData.data.code == 200) {
          
          //let staffData = staffData.data.data;
          this.setState({ staffData: staffData.data.data });
  
        } else {
          this.setState({ error: staffData.data.message });
        }
      } catch (err) {
        console.log("error:" + JSON.stringify(err.response));
        this.setState({ isOtpSent: 'N' });
        this.setState({ error: err.response.data.message });
      }
  
      this.setState({ isLoading: false });
  
    }
  render() {
    
    return (
      <div> 
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="warning">
                <h4 className={{color:'white'}}>Staff Attendance</h4>               
              </CardHeader>
              <CardBody>
              <div style={{ width: '100%' }}>                
                <Table stripped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th> Name </th>
                      <th> Type </th>
                      <th> Mobile </th>
                      <th> Date </th>
                      <th> Status </th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                    this.state.staffData.map((object, i) => {
                      return <tr key={i + 1}>
                        <td>
                          {object['firstName']} {object['lastName']}
                        </td>
                        <td>{object['type']} </td>
                        <td>{object['mobileNo']} </td>
                        <td>{object['attendanceDate']} </td>
                        <td>{object['attendanceStatus']} </td>
                        
                      </tr>
                    })
                  }
                
                  </tbody>
                </Table>
              </div>
              </CardBody>
            </Card>
          </GridItem>          
      </div>
    );
  }
}
export default StaffAttendanceReport;