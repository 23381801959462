import React, { Component } from 'react';

import axios from "axios";
import { Form } from 'react-bootstrap';


export class FeesMonthlyReport extends Component {
  state = {
    userId: '', error: '', userName: '',reportDate:'', classId: '', classList: [], studentList: [], academicYears: [], academicYear: '', collectionDate: '',totalPaidFees:0
  };
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    let userId = localStorage.getItem('userId');
    this.setState({ userId: userId });    
  }

  setReportDate = (reportDate) => {
    console.log("classId:"+reportDate.target.value)
    this.setState({ reportDate: reportDate.target.value });
    this.getFeesCollectionMonthlyReport(reportDate.target.value);
  }

  getFeesCollectionMonthlyReport = async (reportMonth) => {
    try {
      let token = localStorage.getItem("token");
      if (this.state.reportMonth == "") {
        this.setState({ error: "Please select date" })
        return false;
      }

      const headers = { 'Authorization': `Bearer ${token}` };
      let urlData = "&userId=" + this.state.userId + "&reportMonth=" + reportMonth;
      let studentData = await axios.get(process.env.REACT_APP_APIURL + 'report/getfeesmontlyreport?' + urlData, { headers });
      if (studentData.data.code == 200) {
        //console.log("studentData.data" + JSON.stringify(studentData.data));
        this.setState({ studentList: studentData.data.data });
        this.setState({totalPaidFees:studentData.data.totalPaidFees})
      } else {
        //this.setState({ error: result.data.message });
      }
    } catch (err) {
      console.log("error:" + JSON.stringify(err.response));
      this.setState({ isOtpSent: 'N' });
      this.setState({ error: err.response.data.message });
    }
    this.setState({ isLoading: false });
  }
  viewCollection = async (item) => {
    //console.log(JSON.stringify(item));
    window.location.href = "/admin/ViewCollectionDetails/" + item.studentId + "/" + item.classId;
  }
  render() {
    return (
      <div>

        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Monthly Fees Collection</h4>
                <div className='error'>{this.state.error}</div>
                <div>

                  <Form.Group>
                    <label htmlFor="joinDate">Join Date</label>
                    <Form.Control type="date" className="form-control" id="reportDate" value={this.state.reportDate} placeholder="Select date" onChange={this.setReportDate} />
                  </Form.Group>
                </div>
                <div className='btn-gradient-primary' style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', paddingBottom: 10, paddingTop: 10, paddingLeft: 10, paddingRight: 10, marginBottom: 10 }}>
                  <div>Total Fees:{this.state.totalPaidFees}</div>
                </div>

                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Sr</th>
                        <th> Student Name </th>
                        <th> Academic Year </th>
                        <th> Date </th>
                        <th> Amount </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.studentList.map((object, i) => {
                          return <tr key={i + 1}>
                            <td>{i + 1}</td>
                            <td>{object.studentName}</td>
                            <td>{object.academicYear}</td>
                            <td>{object.collectionDate}</td>
                            <td>{object.paid}</td>                         
                          </tr>
                        })
                      }

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}
export default FeesMonthlyReport;