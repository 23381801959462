import React, { Component } from 'react';
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles,withStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
//import CurrencyRupeeIcon from '@material-ui/icons/CurrencyRupee';
import { Accessibility, DateRange,Warning ,Store,LocalOffer,Update,ArrowUpward,Settings,PersonAdd,Visibility,Cloud} 
from '@material-ui/icons';

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
//import Table from "components/Table/Table.js";
import Tasks from "components/Tasks/Tasks.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Danger from "components/Typography/Danger.js";

import { bugs, website, server } from "variables/general.js";
import AddStudent from "components/Students/AddStudent.js"
import ViewStudent from "components/Students/ViewStudent.js"
//import ManageStudent from "components/Students/ManageStudent"
import ManageStudent from "components/Students/MoveToNextClass"
import StudentAttendance from "components/Students/Attendance"


import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
//import '/bootstrap/dist/css/bootstrap.css';
import axios from "axios";

require('dotenv').config()
//const classes = useStyles();
const useStyles = makeStyles(styles);
export class Students extends Component {
  //const classes = useStyles();
  state = {
    userId: '', feesId: '', error: '', studentCount: 0, staffCount: 0, homeTop: [], homeAttendance: [], homeFeesLog: []
    ,classes:[],columns:[]
  };
  handleChange = date => {
    this.setState({
      startDate: date
    });
  };
  constructor(props) {
    super(props)
    //console.log("Props:"+JSON.stringify(props))
    //console.log("Style:"+JSON.stringify(styles))
    
  }
  statusChangedHandler(event, id) {

    //const todoIndex = this.state.todos.findIndex( t => t.id === id );
    const todo = { ...this.state.todos[id] };
    todo.isCompleted = event.target.checked;

    const todos = [...this.state.todos];
    todos[id] = todo;

    this.setState({
      todos: todos
    })
  }

  getDashboardTop = async (userId) => {
    try {
      let token = localStorage.getItem("token");

      const headers = { 'Authorization': `Bearer ${token}` };
      let academicYear = '2023-24'
      let urlData = "&userId=" + userId + "&academicYear=" + academicYear;
      let homeTopData = await axios.get(process.env.REACT_APP_APIURL + 'report/getHomeTop?' + urlData, { headers });
      if (homeTopData.data.code == 200) {
        //console.log("topData.data" + JSON.stringify(homeTopData.data));
        let topObj = homeTopData.data.data;
        //this.setState({ studentList: studentData.data.data });
        this.setState({ homeTop: topObj.feesData });
        this.setState({ studentCount: topObj.studentCount });
        this.setState({ staffCount: topObj.staffCount });

      } else {
        this.setState({ error: homeTopData.data.message });
      }
    } catch (err) {
      console.log("error:" + JSON.stringify(err.response));
      this.setState({ isOtpSent: 'N' });
      this.setState({ error: err.response.data.message });
    }
    this.setState({ isLoading: false });
  }
  getHomeFees = async (userId) => {
    try {
      let token = localStorage.getItem("token");

      const headers = { 'Authorization': `Bearer ${token}` };
      //let academicYear = '2023-24'
      let urlData = "&userId=" + userId;
      let homeTopData = await axios.get(process.env.REACT_APP_APIURL + 'report/getHomeFees?' + urlData, { headers });
      if (homeTopData.data.code == 200) {
        //console.log("topData.data" + JSON.stringify(homeTopData.data));
        let topObj = homeTopData.data.data;
        //this.setState({ studentList: studentData.data.data });
        this.setState({ homeFeesLog: topObj});
      } else {
        this.setState({ error: homeTopData.data.message });      }
    } catch (err) {
      console.log("error:" + JSON.stringify(err.response));
      this.setState({ isOtpSent: 'N' });
      this.setState({ error: err.response.data.message });
    }
    this.setState({ isLoading: false });
  }
  componentDidMount() {
    //your code
    console.log("In Component did Mount")
    
    let cols=[
      { field: 'id', headerName: 'ID' },
      { field: 'studentName', width:'10%', headerName: 'Student Name',flex: 1},
      { field: 'className', headerName: 'Class' ,flex: 1},
      { field: 'collectionAmount', headerName: 'Collected', type: 'number',flex: 1 },
      { field: 'pendingAmount', type: 'number', headerName: 'Pending',flex: 1}
    ]
    this.setState({columns: cols})
    let userId = localStorage.getItem('userId');
    this.setState({ userId: userId });
    this.getDashboardTop(userId)
    this.getHomeFees(userId);
    
  }


  render() {
    
    return (
      <div>
        
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomTabs
              title=""
              headerColor="primary"
              tabs={[
                {
                  tabName: "Add Student",
                  tabIcon: PersonAdd,
                  tabContent: (
                    <AddStudent
                      checkedIndexes={[0, 3]}
                      tasksIndexes={[0, 1, 2, 3]}
                      tasks={'AddStudent'}
                    />
                  ),
                },
                {
                  tabName: "View",
                  tabIcon: Visibility,
                  tabContent: (
                    <ViewStudent
                      checkedIndexes={[0]}
                      tasksIndexes={[0, 1]}
                      tasks={'viewStudent'}
                    />
                  ),
                },
                {
                  tabName: "Manage",
                  tabIcon: Settings,
                  tabContent: (
                    <ManageStudent
                      checkedIndexes={[1]}
                      tasksIndexes={[0, 1, 2]}
                      tasks={"ManageStudent"}
                    />
                  ),
                },{
                  tabName: "Attendance",
                  tabIcon: Settings,
                  tabContent: (
                    <StudentAttendance
                      checkedIndexes={[1]}
                      tasksIndexes={[0, 1, 2]}
                      tasks={"StudentAttendance"}
                    />
                  ),
                },
              ]}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
export default withStyles(useStyles) (Students);