import React, { Component } from 'react';
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles,withStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
//import CurrencyRupeeIcon from '@material-ui/icons/CurrencyRupee';

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
//import Table from "components/Table/Table.js";
import Tasks from "components/Tasks/Tasks.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Danger from "components/Typography/Danger.js";

import { bugs, website, server } from "variables/general.js";

import AddStudentFees from "components/Fees/AddStudentFees"
import ViewFeesStructure from "components/Fees/ViewFeesStructure"
import FeesStructure from "components/Fees/FeesStructure"
import ViewStudentFees from "components/Fees/ViewStudentFees"


import CollectionFees from "components/Fees/FeesCollection"
import FeesPayNow from "components/Fees/FeesPayNow"
import ViewCollection from "components/Fees/ViewCollection"
import { DoneAll,Settings,PersonAdd,Visibility,Cloud} from '@material-ui/icons';

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
//import '/bootstrap/dist/css/bootstrap.css';
import axios from "axios";

require('dotenv').config()
//const classes = useStyles();
const useStyles = makeStyles(styles);
export class FeesCollection extends Component {
  //const classes = useStyles();
  state = {
    userId: '', feesId: '', error: '', studentCount: 0, staffCount: 0, homeTop: [], homeAttendance: [], homeFeesLog: []
    ,classes:[],columns:[]
  };
  handleChange = date => {
    this.setState({
      startDate: date
    });
  };
  constructor(props) {
    super(props)
    console.log("Props:"+JSON.stringify(props))
    console.log("Style:"+JSON.stringify(styles))
    
  }
  componentDidMount() {
    
  }


  render() {
    
    return (
      <div>
        
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomTabs
              title=""
              headerColor="primary"
              tabs={[
                {
                  tabName: "Pay Now",
                  tabIcon: PersonAdd,
                  tabContent: (
                    <CollectionFees
                      checkedIndexes={[0, 3]}
                      tasksIndexes={[0, 1, 2, 3]}
                      tasks={'CollectionFees'}
                    />
                  ),
                },
                {
                  tabName: "View Collection",
                  tabIcon: Cloud,
                  tabContent: (
                    <ViewCollection
                      checkedIndexes={[1]}
                      tasksIndexes={[0, 1, 2]}
                      tasks={"ViewCollection"}
                    />
                  ),
                },
                {
                  tabName: "View Fees Structure",
                  tabIcon: DoneAll,
                  tabContent: (
                    <ViewFeesStructure
                      checkedIndexes={[1]}
                      tasksIndexes={[0, 1, 2]}
                      tasks={"ViewFeesStructure"}
                    />
                  ),
                },
              ]}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
export default withStyles(useStyles) (FeesCollection);