import React, { Component } from 'react';

import axios from "axios";
import { Form } from 'react-bootstrap';
import { BugReport,Code,WhatsApp} from '@material-ui/icons';
//import WhatsAppIcon from '@mui/icons-material/WhatsApp';
export class ViewCollection extends Component {
  state = { classList: [], feesCollections: [], studentList: [], classId: '', studentId: '', error: '', userId: '', className: '', mobileNo: '', studentName: '' }
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    let userId = localStorage.getItem('userId');
    this.setState({ userId: userId });
    this.getClasses(userId);
  }

  getClasses = async (userId) => {

    try {
      let token = localStorage.getItem("token");
      let pageNo = this.state.pageNo + 1;
      const headers = { 'Authorization': `Bearer ${token}` };
      let urlData = "&userId=" + userId;
      let classData = await axios.get(process.env.REACT_APP_APIURL + 'student/getClass?' + urlData, { headers });
      if (classData.data.code == 200) {
        console.log("classData.data" + JSON.stringify(classData.data));
        //let staffData = staffData.data.data;
        this.setState({ classList: classData.data.data });

      } else {
        this.setState({ error: classData.data.message });
      }
    } catch (err) {
      console.log("error:" + JSON.stringify(err.response));
      this.setState({ isOtpSent: 'N' });
      this.setState({ error: err.response.data.message });
    }

    this.setState({ isLoading: false });

  }

  getStudent = async (classId) => {
    try {
      let token = localStorage.getItem("token");
      let pageNo = this.state.pageNo + 1;
      const headers = { 'Authorization': `Bearer ${token}` };
      let urlData = "&userId=" + this.state.userId + "&classId=" + classId;
      let studentData = await axios.get(process.env.REACT_APP_APIURL + 'student/getStudentByClass?' + urlData, { headers });
      if (studentData.data.code == 200) {
        console.log("studentData.data" + JSON.stringify(studentData.data));
        //let staffData = staffData.data.data;
        this.setState({ studentList: studentData.data.data });

      } else {
        this.setState({ error: studentData.data.message });
      }
    } catch (err) {
      console.log("error:" + JSON.stringify(err.response));
      this.setState({ isOtpSent: 'N' });
      this.setState({ error: err.response.data.message });
    }

    this.setState({ isLoading: false });

  }

  setSelectedClass = (classId) => {
    console.log("studentName.target.value" + classId.target.value);
    this.setState({ classId: classId.target.value });
    //this.viewFees(classId.target.value);
    this.getStudent(classId.target.value);
  }
  setStudentId = (studentId) => {
    this.setState({ studentId: studentId.target.value });
    this.getStudentFeescollection(studentId.target.value, this.state.classId)
  }
  getStudentFeescollection = async (studentId, classId) => {

    try {
      let token = localStorage.getItem("token");
      const headers = { 'Authorization': `Bearer ${token}` };
      let urlData = "&userId=" + this.state.userId + "&studentId=" + studentId + "&classId=" + classId;
      let feesCollections = await axios.get(process.env.REACT_APP_APIURL + 'fees/getStudentFeescollection?' + urlData, { headers });
      if (feesCollections.data.code == 200) {
        console.log("feesCollections.data" + JSON.stringify(feesCollections.data));
        //let staffData = staffData.data.data;
        this.setState({ studentName: feesCollections.data.studentName });
        this.setState({ className: feesCollections.data.className });
        this.setState({ mobileNo: feesCollections.data.mobileNo });
        this.setState({ feesCollections: feesCollections.data.data });

      } else {
        this.setState({ error: feesCollections.data.message });
      }
    } catch (err) {
      console.log("error:" + JSON.stringify(err.response));
      this.setState({ isOtpSent: 'N' });
      this.setState({ error: err.response.data.message });
    }

    this.setState({ isLoading: false });
  }
  render() {
    return (
      <div>


        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Student Fees Details</h4>
                <div>
                  <Form.Group className="row">
                    <label className="col-sm-12 col-form-label">Class</label>
                    <div className="col-sm-12">
                      <select className="form-control" value={this.state.classId} onChange={this.setSelectedClass}>
                        <option value={''}>Select Class</option>
                        {
                          this.state.classList.map((object, i) => {
                            return <option key={i} value={object['id']}>{object['className']}</option>
                          })
                        }
                      </select>
                    </div>
                  </Form.Group>
                  <Form.Group className="row">
                    <label className="col-sm-12 col-form-label">Students </label>
                    <div className="col-sm-12">
                      <select className="form-control" value={this.state.studentId} onChange={this.setStudentId}>
                        <option value={''}>Select Student</option>
                        {
                          this.state.studentList.map((object, i) => {
                            return <option key={i} value={object['id']}>{object['firstName']} {object['lastName']}</option>
                          })
                        }
                      </select>
                    </div>
                  </Form.Group>
                </div>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Sr.</th>
                        <th> Student Name </th>
                        <th> Class </th>
                        <th> Mobile </th>
                        <th>Paid</th>
                        <th>Remaining</th>
                        <th> Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.feesCollections.map((object, i) => {
                          return <tr key={i + 1}>
                            <td>{i + 1}</td>
                            <td>{this.state.studentName}</td>
                            <td>{this.state.className}</td>
                            <td>{this.state.mobileNo}</td>
                            <td>{object.collectionAmount}</td>
                            <td>{object.pendingAmount}</td>
                            <td>{object.collectionDate}</td>
                          </tr>
                        })
                      }
                      
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}
export default ViewCollection;