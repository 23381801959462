/*!

=========================================================
* Sunrise English Medium School - v1.10.0
=========================================================

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";


import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
import Unarchive from "@material-ui/icons/Unarchive";
import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import Students from "views/student/Students.js";
import UpdateGradeStudent from "views/student/UpdateGradeStudent.js"
import Staff from "views/staff/Staff.js";
import Fees from "views/Fees/Fees.js";
import FeesCollection from "views/Fees/FeesCollection";

import PayFees from "views/Fees/PayFees";
import FeesReport from "views/Report/FeesReport.js";
import StudentFeesDetails from "views/Report/StudentFeesDetails";
import AttendanceReport from "views/Report/AttendanceReport.js";
import SalaryReport from "views/Report/SalaryReport.js";
import Enquiries from "views/Admissions/Enquiries.js";
import Login from "views/login/Login.js";

import UserProfile from "views/UserProfile/UserProfile.js";
import TableList from "views/TableList/TableList.js";
import Typography from "views/Typography/Typography.js";
import Icons from "views/Icons/Icons.js";
import Maps from "views/Maps/Maps.js";
import NotificationsPage from "views/Notifications/Notifications.js";
import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.js";
// core components/views for RTL layout
//import RTLPage from "views/RTLPage/RTLPage.js";


import AddStudent from "components/Students/AddStudent";


const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "Home",    
    isMenu:true,
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
  },
  {
    path: "/student",
    name: "Student",
    rtlName: "user",    
    isMenu:true,
    icon: Person,
    component: Students,
    layout: "/admin",
  },
  {
    path: "/updatestatus/:studentId/:classId",
    name: "",
    isMenu:false,
    rtlName: "user",
    icon: Person,
    component: UpdateGradeStudent,
    layout: "/admin",
  },
  {
    path: "/staff",
    name: "Staff",
    rtlName: "user",
    isMenu:true,
    icon: Person,
    component: Staff,
    layout: "/admin",
  },
  {
    path: "/fees",
    name: "Fees",
    rtlName: "user",
    isMenu:true,
    icon: Person,
    component: Fees,
    layout: "/admin",
  },
  {
    path: "/feescollection",
    name: "Fees Collection",
    rtlName: "user",
    isMenu:true,
    icon: Person,
    component: FeesCollection,
    layout: "/admin",
  },
  {
    path: "/payfees/:studentId/:classId",
    name: "Pay Fees",
    rtlName: "user",
    isMenu:false,
    icon: Person,
    component: PayFees,
    layout: "/admin",
  },  
  {
    path: "/report",
    name: "Fees Report",
    rtlName: "user",
    isMenu:true,
    icon: Person,
    component: FeesReport,
    layout: "/admin",
  },
  {
    path: "/attendancereort",
    name: "Attendance Report",
    rtlName: "user",
    isMenu:true,
    icon: Person,
    component: AttendanceReport,
    layout: "/admin",
  },
  {
    path: "/salaryreport",
    name: "Salary Report",
    rtlName: "user",
    isMenu:true,
    icon: Person,
    component: SalaryReport,
    layout: "/admin",
  },
  {
    path: "/enquiries",
    name: "Admissions Enquiries",
    rtlName: "user",
    isMenu:true,
    icon: Person,
    component: Enquiries,
    layout: "/admin",
  },{
    path: "/ViewCollectionDetails/:studentId/:classId",
    name: "View Fees Collection",
    rtlName: "user",
    isMenu:false,
    icon: Person,
    component: StudentFeesDetails,
    layout: "/admin",
  },
  {
    path: "/profile",
    name: "Profile",
    rtlName: "user",
    isMenu:false,
    icon: Person,
    component: UserProfile,
    layout: "/admin",
  },
  
  {
    path: "/login",
    name: "",
    rtlName: "user",
    isMenu:false,
    icon: Person,
    component: Login,
    layout: "/admin",
  },
  
  {
    path: "/logout",
    name: "",
    rtlName: "user",
    isMenu:false,
    icon: Person,
    component: Login,
    layout: "/admin",
  },
  
  /*{
    path: "/table",
    name: "Table List",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    component: TableList,
    layout: "/admin",
  },
  {
    path: "/typography",
    name: "Typography",
    rtlName: "طباعة",
    icon: LibraryBooks,
    component: Typography,
    layout: "/admin",
  },
  {
    path: "/icons",
    name: "Icons",
    rtlName: "الرموز",
    icon: BubbleChart,
    component: Icons,
    layout: "/admin",
  },
  {
    path: "/maps",
    name: "Maps",
    rtlName: "",
    icon: LocationOn,
    component: Maps,
    layout: "/admin",
  },
  {
    path: "/notifications",
    name: "Notifications",
    rtlName: "",
    icon: Notifications,
    component: NotificationsPage,
    layout: "/admin",
  },
  {
    path: "/rtl-page",
    name: "RTL Support",
    rtlName: "پشتیبانی از راست به چپ",
    icon: Language,
    component: RTLPage,
    layout: "/rtl",
  },
  {
    path: "/upgrade-to-pro",
    name: "Upgrade To PRO",
    rtlName: "التطور للاحترافية",
    icon: Unarchive,
    component: UpgradeToPro,
    layout: "/admin",
  },*/
];

export default dashboardRoutes;
