import React, { Component } from 'react';

// import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { Form } from 'react-bootstrap';


export class MoveToNextClass extends Component {
  state = {
    userId: '', error: '', userName: '', classId: '', classList: [], studentList: [], academicYears: [], academicYear: '',collectionDate:''
  };
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    let userId = localStorage.getItem('userId');
    this.setState({ userId: userId });
    this.getClasses(userId);
    this.getAcademicYear(userId)
  }

  setSelectedClass = (classId) => {
    this.setState({ classId: classId.target.value });
    this.getStudentFeesByClass(classId.target.value);
  }

  setAcademicYear = (academicYear) => {
    this.setState({ academicYear: academicYear.target.value });
  }
  
  getClasses = async (userId) => {

    try {
      let token = localStorage.getItem("token");
      let pageNo = this.state.pageNo + 1;
      const headers = { 'Authorization': `Bearer ${token}` };
      let urlData = "&userId=" + userId;
      let classData = await axios.get(process.env.REACT_APP_APIURL + 'student/getClass?' + urlData, { headers });
      if (classData.data.code == 200) {
        console.log("classData.data" + JSON.stringify(classData.data));
        //let staffData = staffData.data.data;
        this.setState({ classList: classData.data.data });

      } else {
        this.setState({ error: classData.data.message });
      }
    } catch (err) {
      console.log("error:" + JSON.stringify(err.response));
      this.setState({ isOtpSent: 'N' });
      this.setState({ error: err.response.data.message });
    }

    this.setState({ isLoading: false });

  }


  getAcademicYear = async (userId) => {

    try {
      let token = localStorage.getItem("token");
      let pageNo = this.state.pageNo + 1;
      const headers = { 'Authorization': `Bearer ${token}` };
      let urlData = "&userId=" + userId;
      let academicYearData = await axios.get(process.env.REACT_APP_APIURL + 'student/getAcademicYear?' + urlData, { headers });
      if (academicYearData.data.code == 200) {
        this.setState({ academicYears: academicYearData.data.data });

      } else {
        //this.setState({ error: result.data.message });
      }
    } catch (err) {
      console.log("error:" + JSON.stringify(err.response));
      this.setState({ isOtpSent: 'N' });
      this.setState({ error: err.response.data.message });
    }

    this.setState({ isLoading: false });

  }
  getStudentFeesByClass = async (classId) => {

    try {
      let token = localStorage.getItem("token");
      if (this.state.academicYear == "") {
        this.setState({ error: "Please select academic year" })
        return false;
      }

      const headers = { 'Authorization': `Bearer ${token}` };
      let urlData = "&userId=" + this.state.userId + "&classId=" + classId + "&academicYear=" + this.state.academicYear;
      let studentData = await axios.get(process.env.REACT_APP_APIURL + 'fees/getStuentFeesByClass?' + urlData, { headers });
      if (studentData.data.code == 200) {
        console.log("studentData.data" + JSON.stringify(studentData.data));

        this.setState({ studentList: studentData.data.data });

      } else {
        //this.setState({ error: result.data.message });
      }
    } catch (err) {
      console.log("error:" + JSON.stringify(err.response));
      this.setState({ isOtpSent: 'N' });
      this.setState({ error: err.response.data.message });
    }

    this.setState({ isLoading: false });

  }
  updateStudent=async(item)=>{
    console.log("****"+JSON.stringify(item));
    if(item.pending>0){
        this.setState({error:"Please pay the pending fees to update class status"});
        //return false;
    }
    window.location.href="/admin/updatestatus/"+item.studentId+"/"+item.classId;
  }
  render() {
    return (
      <div>
        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Move Student To Next Class</h4>
                <div className='error'>{this.state.error}</div>
                <div>

                  <Form.Group className="row">
                    <label className="col-sm-12 col-form-label">Academic Year</label>
                    <div className="col-sm-12">
                      <select className="form-control listSelect" value={this.state.academicYear} onChange={this.setAcademicYear}>
                        <option value={''}>Select Academic Year</option>
                        {
                          this.state.academicYears.map((object, i) => {
                            return <option key={i} value={object['academicYear']}>{object['academicYear']}</option>
                          })
                        }
                      </select>
                    </div>
                  </Form.Group>
                  <Form.Group className="row">
                    <label className="col-sm-12 col-form-label">Class</label>
                    <div className="col-sm-12">
                      <select className="form-control listSelect" value={this.state.classId} onChange={this.setSelectedClass}>
                        <option value={''}>Select Class</option>
                        {
                          this.state.classList.map((object, i) => {
                            return <option key={i} value={object['id']}>{object['className']}</option>
                          })
                        }
                      </select>
                    </div>
                  </Form.Group>
                </div>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Sr</th>
                        <th> Student Name </th>
                        <th> Academic Year </th>
                        <th>class Name</th>
                        <th> Total Fees </th>
                        <th> Paid </th>
                        <th> Pending</th>
                        <th>#</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.studentList.map((object, i) => {
                          return <tr key={i + 1}>
                            <td>{i + 1}</td>
                            <td>{object.studentName}</td>
                            <td>{object.academicYear}</td>
                            <td>{object.className}</td>
                            <td>{object.total}</td>
                            <td>{object.paid}</td>
                            <td>{object.pending}</td>
                            <td>
                              <button type="button" className="btn btn-success" onClick={this.updateStudent.bind(this,object)}>Update</button>
                            </td>
                          </tr>
                        })
                      }
                      
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}
export default MoveToNextClass;