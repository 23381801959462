import 'bootstrap/dist/css/bootstrap.min.css';  
import {Container , Alert} from 'react-bootstrap'  
import React, { Component } from 'react';
export class AlertBox extends Component{  
    state={
        show:false,msg:'',variant:'success'
    }
    constructor(props) {
        super(props)
        //console.log("props:"+JSON.stringify(props));
      }
      handleClickFromParent = (show,variant,msg) => {
        //console.log('Child method called from parent!');
        this.setState({show:show,variant:variant,msg:msg})
      }
      setShow=(show)=>{
        this.setState({show:show})
      }
    render() {        
        return ( 
                this.state.show?<div className="App">  
                    <Container className='p-4'>  
                        <Alert onClose={() => this.setShow(false)} variant={this.state.variant} dismissible>{this.state.msg}</Alert>  
                    </Container>
                </div> : null
        )        
    };  
}  
export default AlertBox;  