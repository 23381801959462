import React, { Component } from 'react';
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles,withStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
//import CurrencyRupeeIcon from '@material-ui/icons/CurrencyRupee';
import { Accessibility, DateRange,Warning ,Store,LocalOffer,Update,ArrowUpward,AccessTime,BugReport,Code,Cloud} 
from '@material-ui/icons';

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
//import Table from "components/Table/Table.js";
import Tasks from "components/Tasks/Tasks.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import StaffAttendanceReport from "components/Reports/StaffAttendanceReport.js"
import StudentAttendanceReport from "components/Reports/StudentAttendanceReport.js"
import Table from 'react-bootstrap/Table'
import { bugs, website, server } from "variables/general.js";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
} from "variables/charts.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
//import '/bootstrap/dist/css/bootstrap.css';
import axios from "axios";


//const dotenv = require('dotenv');
//const dotenv = require('dotenv');

require('dotenv').config()
//const classes = useStyles();
const useStyles = makeStyles(styles);
export class Dashboard extends Component {
  //const classes = useStyles();
  state = {
    userId: '', feesId: '', error: '', studentCount: 0, staffCount: 0, homeTop: [], homeAttendance: [], homeFeesLog: []
    ,classes:[],columns:[]
  };
  handleChange = date => {
    this.setState({
      startDate: date
    });
  };
  constructor(props) {
    super(props)
    //console.log("Props:"+JSON.stringify(props))
    //console.log("Style:"+JSON.stringify(styles))
    
  }
  statusChangedHandler(event, id) {

    //const todoIndex = this.state.todos.findIndex( t => t.id === id );
    const todo = { ...this.state.todos[id] };
    todo.isCompleted = event.target.checked;

    const todos = [...this.state.todos];
    todos[id] = todo;

    this.setState({
      todos: todos
    })
  }

  getDashboardTop = async (userId) => {
    try {
      let token = localStorage.getItem("token");

      const headers = { 'Authorization': `Bearer ${token}` };
      let academicYear = '2023-24'
      let urlData = "&userId=" + userId + "&academicYear=" + academicYear;
      let homeTopData = await axios.get(process.env.REACT_APP_APIURL + 'report/getHomeTop?' + urlData, { headers });
      if (homeTopData.data.code == 200) {
        //console.log("topData.data" + JSON.stringify(homeTopData.data));
        let topObj = homeTopData.data.data;
        //this.setState({ studentList: studentData.data.data });
        this.setState({ homeTop: topObj.feesData });
        this.setState({ studentCount: topObj.studentCount });
        this.setState({ staffCount: topObj.staffCount });

        const newVisitSaleData = {
          labels: topObj.attendateData.classList,
          datasets: [
            {
              label: "Absent",
              borderColor: 'red',
              backgroundColor: 'red',
              hoverBackgroundColor: 'red',
              legendColor: 'red',
              pointRadius: 0,
              fill: false,
              borderWidth: 1,
              data: topObj.attendateData.absentStudent
            },
            {
              label: "Present",
              borderColor: 'green',
              backgroundColor: 'green',
              hoverBackgroundColor: 'green',
              legendColor: 'green',
              pointRadius: 0,
              fill: true,
              borderWidth: 1,
              data: topObj.attendateData.presentStudent
            },
            {
              label: "Total",
              borderColor: 'blue',
              backgroundColor: 'blue',
              hoverBackgroundColor: 'blue',
              legendColor: 'blue',
              pointRadius: 0,
              fill: false,
              borderWidth: 1,
              data: topObj.attendateData.totalStudent
            }
          ]
        }
        this.setState({ visitSaleData: newVisitSaleData })
      } else {
        this.setState({ error: homeTopData.data.message });
      }
    } catch (err) {
      //console.log("error:" + JSON.stringify(err.response));
      this.setState({ isOtpSent: 'N' });
      this.setState({ error: err.response.data.message });
    }
    this.setState({ isLoading: false });
  }
  getHomeFees = async (userId) => {
    try {
      let token = localStorage.getItem("token");

      const headers = { 'Authorization': `Bearer ${token}` };
      //let academicYear = '2023-24'
      let urlData = "&userId=" + userId;
      let homeTopData = await axios.get(process.env.REACT_APP_APIURL + 'report/getHomeFees?' + urlData, { headers });
      if (homeTopData.data.code == 200) {
        //console.log("topData.data" + JSON.stringify(homeTopData.data));
        let topObj = homeTopData.data.data;
        //this.setState({ studentList: studentData.data.data });
        this.setState({ homeFeesLog: topObj});
      } else {
        this.setState({ error: homeTopData.data.message });      }
    } catch (err) {
      //console.log("error:" + JSON.stringify(err.response));
      this.setState({ isOtpSent: 'N' });
      this.setState({ error: err.response.data.message });
    }
    this.setState({ isLoading: false });
  }
  componentDidMount() {
    //your code
    console.log("In Component did Mount")
    
    let cols=[
      { field: 'id', headerName: 'ID' },
      { field: 'studentName', width:'10%', headerName: 'Student Name',flex: 1},
      { field: 'className', headerName: 'Class' ,flex: 1},
      { field: 'collectionAmount', headerName: 'Collected', type: 'number',flex: 1 },
      { field: 'pendingAmount', type: 'number', headerName: 'Pending',flex: 1}
    ]
    this.setState({columns: cols})
    let userId = localStorage.getItem('userId');
    this.setState({ userId: userId });
    this.getDashboardTop(userId)
    this.getHomeFees(userId);
    /*
    var ctx = document.getElementById('attendanceChart').getContext("2d")
    var gradientBar1 = ctx.createLinearGradient(0, 0, 0, 181)
    gradientBar1.addColorStop(0, 'rgba(218, 140, 255, 1)')
    gradientBar1.addColorStop(1, 'rgba(154, 85, 255, 1)')

    var gradientBar2 = ctx.createLinearGradient(0, 0, 0, 360)
    gradientBar2.addColorStop(0, 'rgba(54, 215, 232, 1)')
    gradientBar2.addColorStop(1, 'rgba(177, 148, 250, 1)')

    var gradientBar3 = ctx.createLinearGradient(0, 0, 0, 300)
    gradientBar3.addColorStop(0, 'rgba(255, 191, 150, 1)')
    gradientBar3.addColorStop(1, 'rgba(254, 112, 150, 1)')

    var gradientdonut1 = ctx.createLinearGradient(0, 0, 0, 181)
    gradientdonut1.addColorStop(0, 'rgba(54, 215, 232, 1)')
    gradientdonut1.addColorStop(1, 'rgba(177, 148, 250, 1)')

    var gradientdonut2 = ctx.createLinearGradient(0, 0, 0, 50)
    gradientdonut2.addColorStop(0, 'rgba(6, 185, 157, 1)')
    gradientdonut2.addColorStop(1, 'rgba(132, 217, 210, 1)')

    var gradientdonut3 = ctx.createLinearGradient(0, 0, 0, 300)
    gradientdonut3.addColorStop(0, 'rgba(254, 124, 150, 1)')
    gradientdonut3.addColorStop(1, 'rgba(255, 205, 150, 1)')
    */
  }
  toggleProBanner() {
    document.querySelector('.proBanner').classList.toggle("hide");
  }

  render() {
    
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <Icon>currency_rupee</Icon>
                </CardIcon>
                <p style={{color:'red'}}>Total Fees</p>
                <h3 style={{"color":"#3C4858","marginTop":"0px","minHeight":"auto","fontWeight":"300","fontFamily":"'Roboto', 'Helvetica', 'Arial', sans-serif","marginBottom":"3px","textDecoration":"none","& small":{"color":"#777","fontWeight":"400","lineHeight":"1"}}}>
                {this.state.homeTop.totalFess}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={this.props.classes.stats}>
                  
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    Student Total Fees
                  </a>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="success" stats icon>
                
                <CardIcon color="success">
                  <Icon>currency_rupee</Icon>
                </CardIcon>
                <p style={{color:'red'}}>Pending Fees</p>
                <h3 style={{"color":"#3C4858","marginTop":"0px","minHeight":"auto","fontWeight":"300","fontFamily":"'Roboto', 'Helvetica', 'Arial', sans-serif","marginBottom":"3px","textDecoration":"none","& small":{"color":"#777","fontWeight":"400","lineHeight":"1"}}}>
                {this.state.homeTop.totalPending}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={this.props.classes.stats}>
                  Pending Fees
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <Icon>person</Icon>
                </CardIcon>
                <p style={{color:'red'}}>Total Staff</p>
                <h3 style={{"color":"#3C4858","marginTop":"0px","minHeight":"auto","fontWeight":"300","fontFamily":"'Roboto', 'Helvetica', 'Arial', sans-serif","marginBottom":"3px","textDecoration":"none","& small":{"color":"#777","fontWeight":"400","lineHeight":"1"}}}>
                {this.state.staffCount}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={this.props.classes.stats}>
                  Total School Staff
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <Icon>face</Icon>
                </CardIcon>
                <p style={{color:'red'}}>Total Student</p>
                <h3 style={{"color":"#3C4858","marginTop":"0px","minHeight":"auto","fontWeight":"300","fontFamily":"'Roboto', 'Helvetica', 'Arial', sans-serif","marginBottom":"3px","textDecoration":"none","& small":{"color":"#777","fontWeight":"400","lineHeight":"1"}}}>
                  {this.state.studentCount}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={this.props.classes.stats}>
                  
                  Total Students in School
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        
        <GridContainer>          
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="warning">
                <h4 className={this.props.classes.cardTitleWhite}>Fees Collection Details</h4>
               
              </CardHeader>
              <CardBody>
              <div style={{ width: '100%' }}>
                
                <Table stripped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>Student Name</th>
                      <th>Class</th>
                      <th>Collected</th>
                      <th>Pending</th>
                      <th>Date</th>
                
                    </tr>
                  </thead>
                  <tbody>
                  {
                        this.state.homeFeesLog.map((object, i) => {
                          return <tr key={i + 1}>
                            
                            <td>{object.studentName}</td>
                            <td>{object.className}</td>
                            <td><label className="text-success">{object.collectionAmount}</label></td>
                            <td><label className="text-danger">{object.pendingAmount}</label></td>
                            <td>{object.collectionDate}</td>
                          </tr>
                        })
                  }
                
                  </tbody>
                </Table>
              </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        
        <GridContainer>  
          <div className='cols-6 col-md-6'>        
            <StaffAttendanceReport/>
          </div>
          <div className='cols-6 col-md-6'> 
            <StudentAttendanceReport/>
          </div>
          
        </GridContainer>
        
      </div>
    );
  }
}
export default withStyles(useStyles) (Dashboard);