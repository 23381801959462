import React, { Component } from 'react';
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles,withStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
//import CurrencyRupeeIcon from '@material-ui/icons/CurrencyRupee';

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
//import Table from "components/Table/Table.js";
import Tasks from "components/Tasks/Tasks.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Danger from "components/Typography/Danger.js";

import AddEnquiry from "components/Enquiry/AddEnquiry.js"
import ViewEnquiry from "components/Enquiry/ViewEnquiry.js"
import { Assessment,PersonAdd,Visibility,DateRange} from '@material-ui/icons';
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import axios from "axios";

require('dotenv').config()
//const classes = useStyles();
const useStyles = makeStyles(styles);
export class Enquiries extends Component {
  //const classes = useStyles();
  state = {
    userId: '', feesId: '', error: '', studentCount: 0, staffCount: 0, homeTop: [], homeAttendance: [], homeFeesLog: []
    ,classes:[],columns:[]
  };
  handleChange = date => {
    this.setState({
      startDate: date
    });
  };
  constructor(props) {
    super(props)
    //console.log("Props:"+JSON.stringify(props))
    //console.log("Style:"+JSON.stringify(styles))    
  }
  componentDidMount() {
    //your code    
  }


  render() {
    
    return (
      <div>        
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomTabs
              title=""
              headerColor="primary"
              tabs={[
                {
                  tabName: "New Enquiries",
                  tabIcon: PersonAdd,
                  tabContent: (
                    <AddEnquiry
                      checkedIndexes={[0, 3]}
                      tasksIndexes={[0, 1, 2, 3]}
                      tasks={'AddEnquiry'}
                    />
                  ),
                },
                {
                  tabName: "View Enquiries",
                  tabIcon: Visibility,
                  tabContent: (
                    <ViewEnquiry
                      checkedIndexes={[0]}
                      tasksIndexes={[0, 1]}
                      tasks={'FeesMonthlyReport'}
                    />
                  ),
                }
              ]}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
export default withStyles(useStyles) (Enquiries);