
import React, { Component } from 'react';

import axios from "axios";
import { Form } from 'react-bootstrap';
// @material-ui/core components
//import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import avatar from "assets/img/faces/marc.jpg";
import { makeStyles,withStyles } from "@material-ui/core/styles";
import Profile from "components/Staff/Profile.js"
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);
export class UserProfile extends Component {
//export default function UserProfile() {
  state = {
    userId: '', error: '', classes: '',staffData:[],firstName:""
  };
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    let userId = localStorage.getItem('userId');
    //this.getProfile(userId)
  }

  //const classes = useStyles();
  
  
  render() {
    return (
      <div>
        <CardHeader color="primary">
                <h4 className={this.props.classes.cardTitleWhite}>Staff Profile </h4>
                
              </CardHeader>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
          <Profile
                      checkedIndexes={[0, 3]}
                      tasksIndexes={[0, 1, 2, 3]}
                      tasks={'AddStudent'}
                    />
            
          </GridItem>
          
        </GridContainer>
      </div>
    );
  }
}
export default withStyles(useStyles) (UserProfile);
