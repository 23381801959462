import React, { Component } from 'react';

import axios from "axios";
import { Form } from 'react-bootstrap';
// import "react-datepicker/dist/react-datepicker.css";
import Button from "components/CustomButtons/Button.js";
const dotenv = require('dotenv');

export class FeesStructure extends Component {
  state = {
    userId: '', error: '', userName: '', classId: '', selectedClass: 0, feesId: 0, classList: [],
    admitionFees: '', tutionFees: '', dressFees: '', bookFees: '', cabFees: '', libraryFees: '', other: '', classData: {}
  };
  handleChange = date => {
    this.setState({
      startDate: date
    });
  };
  constructor(props) {
    super(props);

    console.log("process.env.REACT_APP_APIURL=" + process.env.REACT_APP_APIURL);
  }
  setSelectedClass = (classId) => {
    console.log("studentName.target.value" + classId.target.value);
    this.setState({ classId: classId.target.value });
    this.setState({ admitionFees: '' });
      this.setState({ tutionFees: '' });
      this.setState({ dressFees: '' });
      this.setState({ bookFees: '' });
      this.setState({ cabFees: '' });
      this.setState({ libraryFees: '' });
      this.setState({ other: '' });
    this.viewFees(classId.target.value);
  }
  setAdmitionFees = (admitionFees) => {
    console.log("studentName.target.value" + admitionFees.target.value);
    this.setState({ admitionFees: admitionFees.target.value });
  }
  setLibraryFees = (libraryFees) => {
    console.log("libraryFees" + libraryFees.target.value);
    this.setState({ libraryFees: libraryFees.target.value });
  }
  setTutionFees = (tutionFees) => {
    this.setState({ tutionFees: tutionFees.target.value });
  }
  setDressFees = (dressFees) => {
    this.setState({ dressFees: dressFees.target.value });
  }
  setBookFees = (bookFees) => {
    this.setState({ bookFees: bookFees.target.value });
  }
  setCabFees = (cabFees) => {
    this.setState({ cabFees: cabFees.target.value });
  }
  setOther = (other) => {
    this.setState({ other: other.target.value });
  }

  statusChangedHandler(event, id) {

    //const todoIndex = this.state.todos.findIndex( t => t.id === id );
    const todo = { ...this.state.todos[id] };
    todo.isCompleted = event.target.checked;

    const todos = [...this.state.todos];
    todos[id] = todo;

    this.setState({
      todos: todos
    })
  }
  inputChangeHandler(event) {
    this.setState({
      inputValue: event.target.value
    });
  }
  componentDidMount() {
    let userId = localStorage.getItem('userId');
    let userName = localStorage.getItem('userName');

    this.setState({ userId: userId });
    this.setState({ userName: userName });
    console.log("userId:************************" + userId);
    this.getClasses(userId);
  }
  getClasses = async (userId) => {

    try {
      let token = localStorage.getItem("token");
      let pageNo = this.state.pageNo + 1;
      const headers = { 'Authorization': `Bearer ${token}` };
      let urlData = "&userId=" + userId;
      let classData = await axios.get(process.env.REACT_APP_APIURL + 'student/getClass?' + urlData, { headers });
      if (classData.data.code == 200) {
        console.log("classData.data" + JSON.stringify(classData.data));
        //let staffData = staffData.data.data;
        this.setState({ classList: classData.data.data });

      } else {
        this.setState({ error: classData.data.message });
      }
    } catch (err) {
      console.log("error:" + JSON.stringify(err.response));
      this.setState({ isOtpSent: 'N' });
      this.setState({ error: err.response.data.message });
    }

    this.setState({ isLoading: false });

  }
  addFees = async () => {
    console.log("addFees");
    let token = localStorage.getItem("token");
    console.log("UserId:" + this.state.userId)
    const headers = { 'Authorization': `Bearer ${token}` };
    let carModelNo = '';
    let carNo = '';
    if (this.state.userId == "" || this.state.userId == null) {
      this.setState({ error: "Something went wrong please try after sometime" })
      return false;
    }
    if (this.state.classId == "" || this.state.classId == null) {
      this.setState({ error: "Please select class" })
      return false;
    }
    if (this.state.admitionFees == "" || this.state.admitionFees == null) {
      this.setState({ error: "Please enter admition Fees" })
      return false;
    }
    if (this.state.tutionFees == "" || this.state.tutionFees == null) {
      this.setState({ error: "Please enter tution Fees" })
      return false;
    }
    if (this.state.dressFees == "" || this.state.dressFees == null) {
      this.setState({ error: "Please enter dress Fees" })
      return false;
    }
    if (this.state.bookFees == "" || this.state.bookFees == null) {
      this.setState({ error: "Please enter book Fees" })
      return false;
    }
    if (this.state.cabFees == "" || this.state.cabFees == null) {
      this.setState({ error: "Please enter Cab Fees" })
      return false;
    }
    if (this.state.libraryFees == "" || this.state.libraryFees == null) {
      this.setState({ error: "Please enter Library Fees" })
      return false;
    }
    if (this.state.other == "" || this.state.other == null) {
      this.setState({ error: "Please enter other Fees" })
      return false;
    }
    const data = {
      userId: this.state.userId,
      classId: this.state.classId,
      admissionFees: this.state.admitionFees,
      tutionFees: this.state.tutionFees,
      dressFees: this.state.dressFees,
      bookFees: this.state.bookFees,
      cabFees: this.state.cabFees,
      libraryFees: this.state.libraryFees,
      other: this.state.other,
    };

    //console.log("=======" + data)
    const result = await axios.post(process.env.REACT_APP_APIURL + "fees/addClassFees", data, { headers });
    console.log("result" + result);
    if (result.code == 401) {
      console.log("LogOut");
      
    }
    if (result.data.code == 200) {
      this.setState({ error: result.data.message });
      this.setState({ error: result.error });
      this.setState({ classId: '' });
      this.setState({ admitionFees: '' });
      this.setState({ tutionFees: '' });
      this.setState({ dressFees: '' });
      this.setState({ bookFees: '' });
      this.setState({ cabFees: '' });
      this.setState({ libraryFees: '' });
      this.setState({ other: '' });

    } else {

      this.setState({ error: result.data.message })
    }
    this.setState({ isLoading: false });
  }
  editFees = async () => {
    try {

      let token = localStorage.getItem("token");
      console.log("UserId:" + this.state.userId)
      const headers = { 'Authorization': `Bearer ${token}` };
      let carModelNo = '';
      let carNo = '';
      if (this.state.userId == "" || this.state.userId == null) {
        this.setState({ error: "Something went wrong please try after sometime" })
        return false;
      }
      if (this.state.feesId == "" || this.state.feesId == null) {
        this.setState({ error: "Something went wrong please try after sometime" })
        return false;
      }
      if (this.state.classId == "" || this.state.classId == null) {
        this.setState({ error: "Please select class" })
        return false;
      }
      if (this.state.admitionFees == "" || this.state.admitionFees == null) {
        this.setState({ error: "Please enter admition Fees" })
        return false;
      }
      if (this.state.tutionFees == "" || this.state.tutionFees == null) {
        this.setState({ error: "Please enter tution Fees" })
        return false;
      }
      if (this.state.dressFees == "" || this.state.dressFees == null) {
        this.setState({ error: "Please enter dress Fees" })
        return false;
      }
      if (this.state.bookFees == "" || this.state.bookFees == null) {
        this.setState({ error: "Please enter book Fees" })
        return false;
      }
      if (this.state.cabFees == "" || this.state.cabFees == null) {
        this.setState({ error: "Please enter Cab Fees" })
        return false;
      }
      if (this.state.libraryFees == "" || this.state.libraryFees == null) {
        this.setState({ error: "Please enter Library Fees" })
        return false;
      }
      if (this.state.other == "" || this.state.other == null) {
        this.setState({ error: "Please enter other Fees" })
        return false;
      }
      const data = {
        userId: this.state.userId,
        feesId: this.state.feesId,
        classId: this.state.classId,
        admissionFees: this.state.admitionFees,
        tutionFees: this.state.tutionFees,
        dressFees: this.state.dressFees,
        bookFees: this.state.bookFees,
        cabFees: this.state.cabFees,
        libraryFees: this.state.libraryFees,
        other: this.state.other,
      };
      console.log("=======" + data)
      const result = await axios.put(process.env.REACT_APP_APIURL + "fees/updateClassFees", data, { headers });
      console.log("result" + result);
      if (result.code == 401) {
        console.log("LogOut");
        this.setState({ error: result.error });
      }
      if (result.data.code == 200) {
        this.setState({ error: result.data.message });

      } else {

        this.setState({ error: result.data.message })
      }
      this.setState({ isLoading: false });
    } catch (err) {

      this.setState({ isOtpSent: 'N' });
      this.setState({ error: err.response.data.message });
    }

    this.setState({ isLoading: false });
  }
  viewFees = async (classId) => {

    try {
      let token = localStorage.getItem("token");
      let pageNo = this.state.pageNo + 1;
      const headers = { 'Authorization': `Bearer ${token}` };
      let urlData = "&userId=" + this.state.userId + "&classId=" + classId;
      let cabData = await axios.get(process.env.REACT_APP_APIURL + 'fees/getFeesByClass?' + urlData, { headers });
      if (cabData.data.code == 200) {
        console.log("cabData.data" + JSON.stringify(cabData.data));
        let classData = cabData.data.data;
        this.setState({ classData: cabData.data.data });
        this.setState({ feesId: classData.id });
        this.setState({ selectedClass: classData.classId });
        this.setState({ admitionFees: classData.admissionFees });
        this.setState({ tutionFees: classData.tutionFees });
        this.setState({ dressFees: classData.dressFees });
        this.setState({ bookFees: classData.bookFees });
        this.setState({ cabFees: classData.cabFees });
        this.setState({ libraryFees: classData.libraryFees });
        this.setState({ other: classData.other });


      } else {
        //this.setState({ error: result.data.message });
      }
    } catch (err) {
      console.log("error:" + JSON.stringify(err.response));
      this.setState({ isOtpSent: 'N' });
      this.setState({ error: err.response.data.message });
    }

    this.setState({ isLoading: false });

  }
  render() {
    return (
      <div>
        {/*<div className="proBanner">
          <div>
            <span className="d-flex align-items-center purchase-popup">
              <p>Get tons of UI components, Plugins, multiple layouts, 20+ sample pages, and more!</p>
              <a href="https://www.bootstrapdash.com/product/purple-react/?utm_source=organic&utm_medium=banner&utm_campaign=free-preview" rel="noopener noreferrer" target="_blank" className="btn btn-sm purchase-button ml-auto">Check Pro Version</a>
              <i className="mdi mdi-close bannerClose" onClick={this.toggleProBanner}></i>
            </span>
          </div>
    </div>*/}

        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Fees Structure</h4>
                <div className='error'>{this.state.error}</div>
                <form className="forms-sample">
                  <Form.Group className="row">
                    <label className="col-sm-12 col-form-label">Class</label>
                    <div className="col-sm-12">
                      <select className="form-control" value={this.state.classId} onChange={this.setSelectedClass}>
                        <option value={''}>Select Class</option>
                        {
                          this.state.classList.map((object, i) => {
                            return <option key={i} value={object['id']}>{object['className']}</option>
                          })
                        }

                      </select>
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputUsername1">Admition Fees</label>
                    <Form.Control type="text" id="exampleInputUsername1" value={this.state.admitionFees} placeholder="Admition Fees" onChange={this.setAdmitionFees} />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputEmail1">Tution Fees</label>
                    <Form.Control type="text" className="form-control" id="" value={this.state.tutionFees} placeholder="Tution Fees" onChange={this.setTutionFees} />
                  </Form.Group>

                  <Form.Group>
                    <label htmlFor="exampleInputEmail1">Dress Fees</label>
                    <Form.Control type="text" className="form-control" id="exampleInputEmail1" placeholder="Dress Fees" value={this.state.dressFees} onChange={this.setDressFees} />
                  </Form.Group>


                  <Form.Group>
                    <label htmlFor="exampleInputEmail1">Book Fees</label>
                    <Form.Control type="text" className="form-control" value={this.state.bookFees} id="exampleInputEmail1" placeholder="Book Fees" onChange={this.setBookFees} />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputEmail1">Library Fees</label>
                    <Form.Control type="text" className="form-control" value={this.state.libraryFees} id="exampleInputEmail1" placeholder="Library Fees" onChange={this.setLibraryFees} />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputEmail1">Cab Fees</label>
                    <Form.Control type="text" className="form-control" value={this.state.cabFees} id="exampleInputEmail1" placeholder="Cab Fees" onChange={this.setCabFees} />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputEmail1">Other</label>
                    <Form.Control type="text" className="form-control" value={this.state.other} id="exampleInputEmail1" placeholder="Other" onChange={this.setOther} />
                  </Form.Group>
                  {
                    this.state.feesId > 0 ? <Button color="primary" title="Send Fees Reminder" className="btn btn-gradient-primary mr-2"  onClick={this.editFees.bind(this)}>Update</Button> : <Button color="primary" title="Send Fees Reminder" className="btn btn-gradient-primary mr-2"  onClick={this.addFees.bind(this)}>Save</Button>
                  }

                </form>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}
const ListItem = (props) => {

  return (
    <li className={(props.isCompleted ? 'completed' : null)}>
      <div className="form-check">
        <label htmlFor="" className="form-check-label">
          <input className="checkbox" type="checkbox"
            checked={props.isCompleted}
            onChange={props.changed}
          /> {props.children} <i className="input-helper"></i>
        </label>
      </div>
      <i className="remove mdi mdi-close-circle-outline" onClick={props.remove}></i>
    </li>
  )
};
export default FeesStructure;