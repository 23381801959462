import React, { Component } from 'react';

import axios from "axios";
import { Form } from 'react-bootstrap';


export class FeesCollectionReport extends Component {
  state = {
    userId: '', error: '', userName: '', classId: '', classList: [], studentList: [], academicYears: [], 
    academicYear: '', collectionDate: '',totalFees:0,paidFees:0,pendingFees:0
  };
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    let userId = localStorage.getItem('userId');
    this.setState({ userId: userId });
    this.getClasses(userId);
    this.getAcademicYear(userId)
  }

  setSelectedClass = (classId) => {
    this.setState({ classId: classId.target.value });
    this.getFeesCollectionReport(classId.target.value);
  }

  setAcademicYear = (academicYear) => {
    this.setState({ academicYear: academicYear.target.value });
  }

  getClasses = async (userId) => {

    try {
      let token = localStorage.getItem("token");
      let pageNo = this.state.pageNo + 1;
      const headers = { 'Authorization': `Bearer ${token}` };
      let urlData = "&userId=" + userId;
      let classData = await axios.get(process.env.REACT_APP_APIURL + 'student/getClass?' + urlData, { headers });
      if (classData.data.code == 200) {
        //console.log("classData.data" + JSON.stringify(classData.data));
        //let staffData = staffData.data.data;
        this.setState({ classList: classData.data.data });

      } else {
        this.setState({ error: classData.data.message });
      }
    } catch (err) {
      console.log("error:" + JSON.stringify(err.response));
      this.setState({ isOtpSent: 'N' });
      this.setState({ error: err.response.data.message });
    }

    this.setState({ isLoading: false });

  }

  getAcademicYear = async (userId) => {

    try {
      let token = localStorage.getItem("token");
      let pageNo = this.state.pageNo + 1;
      const headers = { 'Authorization': `Bearer ${token}` };
      let urlData = "&userId=" + userId;
      let academicYearData = await axios.get(process.env.REACT_APP_APIURL + 'student/getAcademicYear?' + urlData, { headers });
      if (academicYearData.data.code == 200) {
        this.setState({ academicYears: academicYearData.data.data });

      } else {
        //this.setState({ error: result.data.message });
      }
    } catch (err) {
      console.log("error:" + JSON.stringify(err.response));
      this.setState({ isOtpSent: 'N' });
      this.setState({ error: err.response.data.message });
    }

    this.setState({ isLoading: false });

  }
  getFeesCollectionReport = async (classId) => {

    try {
      let token = localStorage.getItem("token");
      if (this.state.academicYear == "") {
        this.setState({ error: "Please select academic year" })
        return false;
      }

      const headers = { 'Authorization': `Bearer ${token}` };
      let urlData = "&userId=" + this.state.userId + "&classId=" + classId + "&academicYear=" + this.state.academicYear;
      let studentData = await axios.get(process.env.REACT_APP_APIURL + 'report/getFeesCollectionReport?' + urlData, { headers });
      if (studentData.data.code == 200) {
        //console.log("studentData.data" + JSON.stringify(studentData.data));
        var feesData=studentData.data.data;
        //feesData.sort((a, b) => (a.studentName > b.studentName ? 1 : -1));
        feesData=await this.sortData(feesData, "studentName");
        //console.log("feesData3:"+JSON.stringify(feesData3));
        this.setState({totalFees:studentData.data.totalFees});
        this.setState({paidFees:studentData.data.totalPaidFees});
        this.setState({pendingFees:studentData.data.totalPendingFees});
        
        this.setState({ studentList: feesData });

      } else {
        //this.setState({ error: result.data.message });
      }
    } catch (err) {
      console.log("error:" + JSON.stringify(err.response));
      this.setState({ isOtpSent: 'N' });
      this.setState({ error: err.response.data.message });
    }

    this.setState({ isLoading: false });

  }
  sortData = async(object,col)=>{
    let data=object.sort((a, b) => (a[col] > b[col] ? 1 : -1));
    //console.log("data:"+JSON.stringify(data));
    return data;
  }
  viewCollection = async (item) => {
    console.log(JSON.stringify(item));
    window.location.href = "/admin/ViewCollectionDetails/" + item.studentId + "/" + item.classId;
  }
  render() {
    return (
      <div>

        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Student Fees Collection</h4>
                <div className='error'>{this.state.error}</div>
                <div>

                  <Form.Group className="row">
                    <label className="col-sm-12 col-form-label">Academic Year</label>
                    <div className="col-sm-12">
                      <select className="form-control listSelect" value={this.state.academicYear} onChange={this.setAcademicYear}>
                        <option value={''}>Select Academic Year</option>
                        {
                          this.state.academicYears.map((object, i) => {
                            return <option key={i} value={object['academicYear']}>{object['academicYear']}</option>
                          })
                        }
                      </select>
                    </div>
                  </Form.Group>
                  <Form.Group className="row">
                    <label className="col-sm-12 col-form-label">Class</label>
                    <div className="col-sm-12">
                      <select className="form-control listSelect" value={this.state.classId} onChange={this.setSelectedClass}>
                        <option value={''}>Select Class</option>
                        {
                          this.state.classList.map((object, i) => {
                            return <option key={i} value={object['id']}>{object['className']}</option>
                          })
                        }
                      </select>
                    </div>
                  </Form.Group>
                </div>
                <div className='btn-gradient-primary' style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', paddingBottom: 10, paddingTop: 10, paddingLeft: 10, paddingRight: 10, marginBottom: 10 }}>
                  <div style={{fontWeight:"bold"}}>Total Fees: {this.state.totalFees}</div>
                  <div style={{fontWeight:"bold"}}>Paid Fees:{this.state.paidFees}</div>
                  <div style={{fontWeight:"bold"}}>Remaining Fees:{this.state.pendingFees}</div>
                </div>

                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Sr</th>
                        <th> Student Name </th>
                        <th> Academic Year </th>
                        <th> Total Fees </th>
                        <th> Paid </th>
                        <th> Pending</th>
                        <th>#</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.studentList.map((object, i) => {
                          return <tr key={i + 1}>
                            <td>{i + 1}</td>
                            <td>{object.studentName}</td>
                            <td>{object.academicYear}</td>
                            <td>{object.total}</td>
                            <td><label className="success">{object.paid}</label></td>
                            <td><label className="danger">{object.pending}</label></td>
                            <td>
                              <button type="button" className="btn btn-gradient-success" onClick={this.viewCollection.bind(this, object)}>Details</button>
                            </td>
                          </tr>
                        })
                      }

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}
export default FeesCollectionReport;