import React, { Component } from 'react';

import axios from "axios";


export class ViewEnquiry extends Component {
  state = {
    studentList: [], userId: '', error: '', classes: []
  }
  constructor(props) {
    super(props)

  }

  componentDidMount() {
    //your code
    let userId = localStorage.getItem('userId');
    this.setState({ userId: userId });
    console.log("userId:************************" + userId);
    this.getClasses(userId);
  }
  getClasses = async (userId) => {

    try {
      let token = localStorage.getItem("token");
      let pageNo = this.state.pageNo + 1;
      const headers = { 'Authorization': `Bearer ${token}` };
      let urlData = "&userId=" + userId;
      let classData = await axios.get(process.env.REACT_APP_APIURL + 'student/getClass?' + urlData, { headers });
      if (classData.data.code == 200) {
        console.log("classData.data" + JSON.stringify(classData.data));
        //let staffData = staffData.data.data;
        this.setState({ classes: classData.data.data });

      } else {
        this.setState({ error: classData.data.message });
      }
    } catch (err) {
      console.log("error:" + JSON.stringify(err.response));
      this.setState({ isOtpSent: 'N' });
      this.setState({ error: err.response.data.message });
    }

    this.setState({ isLoading: false });

  }
  getStudentByClass = async (classId) => {

    try {
      let token = localStorage.getItem("token");
      let pageNo = this.state.pageNo + 1;
      const headers = { 'Authorization': `Bearer ${token}` };
      let urlData = "&userId=" + this.state.userId + "&classId=" + classId;
      
      let studentData = await axios.get(process.env.REACT_APP_APIURL + 'student/getEnquiry?' + urlData, { headers });
      if (studentData.data.code == 200) {
        console.log("studentData.data" + JSON.stringify(studentData.data));
        
        this.setState({ studentList: studentData.data.data });

      } else {
        //this.setState({ error: result.data.message });
      }
    } catch (err) {
      console.log("error:" + JSON.stringify(err.response));
      this.setState({ isOtpSent: 'N' });
      this.setState({ error: err.response.data.message });
    }

    this.setState({ isLoading: false });

  }
  setSelectedClass = (event) => {
    let classId = event.target.value;
    this.getStudentByClass(classId);
  }

  render() {
    return (
      <div>
        {/*<div className="proBanner">
          <div>
            <span className="d-flex align-items-center purchase-popup">
              <p>Get tons of UI components, Plugins, multiple layouts, 20+ sample pages, and more!</p>
              <a href="https://www.bootstrapdash.com/product/purple-react/?utm_source=organic&utm_medium=banner&utm_campaign=free-preview" rel="noopener noreferrer" target="_blank" className="btn btn-sm purchase-button ml-auto">Check Pro Version</a>
              <i className="mdi mdi-close bannerClose" onClick={this.toggleProBanner}></i>
            </span>
          </div>
    </div>*/}

        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Admission Enquiries</h4>
                <div>
                  <div className='error'>{this.state.error}</div>
                  <select className="form-control" value={this.state.classId} onChange={this.setSelectedClass}>
                    <option value={''}>Select Class</option>
                    {
                      this.state.classes.map((object, i) => {
                        return <option key={i} value={object['id']}>{object['className']}</option>
                      })
                    }
                  </select>
                </div>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Sr</th>
                        <th> Student Name </th>
                        <th> Father Name </th>
                        <th> Mother Name </th>
                        <th>Gender</th>
                        <th>DateOfBirth</th>
                        <th> Class </th>
                        <th> Mobile </th>
                        <th> Address</th>
                        <th>Academic Year</th>
                      </tr>
                    </thead>
                    <tbody>
                      {                        
                        this.state.studentList.map((object, i) => {
                          return <tr key={i + 1}>
                            <td>{i+1}</td>
                            <td>{object.firstName} {object.lastName}</td>
                            <td>{object.fatherName}</td>
                            <td>{object.motherName}</td>
                            <td>{object.gender}</td>
                            <td>{object.dateOfBirth}</td>
                            <td>{object.classId}</td>
                            <td>{object.mobileNo}</td>
                            <td>{object.address}</td>
                            <td>{object.academicYear}</td>
                            </tr>
                        })
                      }
                    
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default ViewEnquiry;