import React, { Component } from 'react';

import axios from "axios";


export class ViewFeesStructure extends Component {
  state = {
    userId: '', error: '', userName: '', classId: '', feesData: []
  };
  handleChange = date => {
    this.setState({
      startDate: date
    });
  };
  constructor(props) {
    super(props)

  }

  componentDidMount() {
    //your code
    let userId = localStorage.getItem('userId');
    let userName = localStorage.getItem('userName');

    this.setState({ userId: userId });
    this.setState({ userName: userName });
    console.log("userId:************************" + userId);
    this.getFees(userId);
  }
  getFees = async (userId) => {

    try {
      let token = localStorage.getItem("token");
      let pageNo = this.state.pageNo + 1;
      const headers = { 'Authorization': `Bearer ${token}` };
      let urlData = "&userId=" + userId;
      let feesData = await axios.get(process.env.REACT_APP_APIURL + 'fees/getFees?' + urlData, { headers });
      if (feesData.data.code == 200) {
        console.log("feesData.data" + JSON.stringify(feesData.data));
        let classData = feesData.data.data;
        this.setState({ feesData: feesData.data.data });

      } else {
        //this.setState({ error: result.data.message });
      }
    } catch (err) {
      console.log("error:" + JSON.stringify(err.response));
      this.setState({ isOtpSent: 'N' });
      this.setState({ error: err.response.data.message });
    }

    this.setState({ isLoading: false });

  }
  render() {
    return (
      <div>
        {/*<div className="proBanner">
          <div>
            <span className="d-flex align-items-center purchase-popup">
              <p>Get tons of UI components, Plugins, multiple layouts, 20+ sample pages, and more!</p>
              <a href="https://www.bootstrapdash.com/product/purple-react/?utm_source=organic&utm_medium=banner&utm_campaign=free-preview" rel="noopener noreferrer" target="_blank" className="btn btn-sm purchase-button ml-auto">Check Pro Version</a>
              <i className="mdi mdi-close bannerClose" onClick={this.toggleProBanner}></i>
            </span>
          </div>
    </div>*/}

        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Student Fees Structure</h4>

                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th> Sr </th>
                        <th> Class </th>
                        <th> Admition Fees </th>
                        <th> Tution Fees</th>
                        <th> Dress Fees </th>
                        <th> Book Fees </th>
                        <th> Library Fees</th>
                        <th> Cab Fees</th>
                        <th> Other</th>
                        <th> Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.feesData.map((object, i) => {
                          return <tr key={i + 1}>
                            <td>{i+1}</td>
                            <td>{object.Class.className}</td>
                            <td>{object.admissionFees}</td>
                            <td>{object.tutionFees}</td>
                            <td>{object.dressFees}</td>
                            <td>{object.bookFees}</td>
                            <td>{object.libraryFees}</td>
                            <td>{object.cabFees}</td>
                            <td>{object.other}</td>
                            <td>{object.admissionFees+object.tutionFees+object.dressFees+object.bookFees+object.libraryFees+object.cabFees+object.other}</td>
                          </tr>
                        })
                      }
                      

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}
const ListItem = (props) => {

  return (
    <li className={(props.isCompleted ? 'completed' : null)}>
      <div className="form-check">
        <label htmlFor="" className="form-check-label">
          <input className="checkbox" type="checkbox"
            checked={props.isCompleted}
            onChange={props.changed}
          /> {props.children} <i className="input-helper"></i>
        </label>
      </div>
      <i className="remove mdi mdi-close-circle-outline" onClick={props.remove}></i>
    </li>
  )
};
export default ViewFeesStructure;