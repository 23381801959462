import React, { Component } from "react";
//import { Bar, Doughnut } from 'react-chartjs-2';
//import DatePicker from "react-datepicker";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
//import CustomInput from "components/CustomInput/CustomInput.js";
//import Button from "components/CustomButtons/Button.js";
require('dotenv').config();
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
//import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { Form } from 'react-bootstrap';
export class Profile extends Component {
  state = {
    classes: [], academicYear: '', academicYears: [],staffData:[],
    firstName: 'ak', lastName: '', fatherName: 'asd', motherName: '', dateOfBirth: '', classId: '', mobileNo: '', email: '', address: '', classesList: [], gender: '', admissionDate: ''
  };
  
  constructor(props) {
    super(props)
    
  }

  componentDidMount() {
    let userId = localStorage.getItem('userId');
    this.setState({ userId: userId });
    console.log("userId:************************" + userId);
    
    this.getProfile(userId);
  }

  getProfile = async (userId) => {
    
    try {
      //let userId = localStorage.getItem('userId');
      let token = localStorage.getItem("token");
      
      const headers = { 'Authorization': `Bearer ${token}` };
      let urlData = "&staffId=" + userId;
      let staffData = await axios.get(process.env.REACT_APP_APIURL + 'staff/getstaffById?' + urlData, { headers });
      if (staffData.data.code == 200) {
        
        let staffDataObj = staffData.data.data;
        console.log("staffDataObj:"+JSON.stringify(staffDataObj))
        this.setState({ staffData: staffDataObj });
        this.setState({firstName:staffDataObj['firstName']});
        console.log("First name:"+staffDataObj['firstName']);
        //let userName=staffDataObj['firstName']+" "+staffDataObj['lastName'];
        //let token=staffData.data.token;
        

      } else {
        //this.setState({ error: result.data.message });
      }
    } catch (err) {
      //console.log("error:" + JSON.stringify(err.response));
      this.setState({ isOtpSent: 'N' });
      this.setState({ error: err.response.data.message });
    }

    this.setState({ isLoading: false });

    //window.href='/dashboard';
  }
  render() {
    return (
      <div>
        <Card>
            
            <CardBody>
              <GridContainer>    
                <GridItem xs={12} sm={12} md={6}>
                
                    
                  
                </GridItem>
                
              </GridContainer>
              <GridContainer>
                <div>{this.state.error}</div>
                <GridItem xs={12} sm={12} md={6}>
                    <Form.Group>
                        <label htmlFor="lastName">First Name</label>
                        <Form.Control type="text" className="form-control" id="lastName" placeholder="Last Name" value={this.state.firstName}/>
                    </Form.Group>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>                
                    <Form.Group>
                        <label htmlFor="lastName">Last Name</label>
                        <Form.Control type="text" className="form-control" id="lastName" placeholder="Last Name" value={this.state.staffData.lastName}/>
                    </Form.Group>
                </GridItem>
                
                <GridItem xs={12} sm={12} md={6}>                
                    <Form.Group>
                        <label htmlFor="lastName">Gender</label>
                        <Form.Control type="text" className="form-control" id="lastName" placeholder="Gender" value={this.state.staffData.gender}/>
                    </Form.Group>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>                
                    <Form.Group>
                        <label htmlFor="lastName">Type</label>
                        <Form.Control type="text" className="form-control" id="lastName" placeholder="Type" value={this.state.staffData.type}/>
                    </Form.Group>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>                
                    <Form.Group>
                        <label htmlFor="lastName">Mobile No</label>
                        <Form.Control type="text" className="form-control" id="mobileNo" placeholder="Mobile No" value={this.state.staffData.mobileNo}/>
                    </Form.Group>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>                
                    <Form.Group>
                        <label htmlFor="lastName">Email</label>
                        <Form.Control type="text" className="form-control" id="email" placeholder="Email" value={this.state.staffData.email}/>
                    </Form.Group>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>                
                    <Form.Group>
                        <label htmlFor="lastName">Address</label>
                        <Form.Control type="text" className="form-control" id="lastName" placeholder="Address" value={this.state.staffData.address}/>
                    </Form.Group>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>                
                    <Form.Group>
                        <label htmlFor="lastName">Education</label>
                        <Form.Control type="text" className="form-control" id="education" placeholder="Education" value={this.state.staffData.education}/>
                    </Form.Group>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>                
                    <Form.Group>
                        <label htmlFor="lastName">Experience</label>
                        <Form.Control type="text" className="form-control" id="experience" placeholder="Experience" value={this.state.staffData.experience}/>
                    </Form.Group>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>                
                    <Form.Group>
                        <label htmlFor="lastName">salary</label>
                        <Form.Control type="text" className="form-control" id="salary" placeholder="salary" value={this.state.staffData.salary}/>
                    </Form.Group>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>                
                    <Form.Group>
                        <label htmlFor="lastName">Joined On</label>
                        <Form.Control type="text" className="form-control" id="joinDate" placeholder="joinDate" value={this.state.staffData.joinDate}/>
                    </Form.Group>
                </GridItem>
              </GridContainer>
              
              
            </CardBody>
            <CardFooter>
              
            </CardFooter>
        </Card>
        

      </div>
    );
  }
}
export default Profile;