import React, { Component } from 'react';

import axios from "axios";
import { Form } from 'react-bootstrap';

export class ViewStudentFees extends Component {
    state = {
        userId: '', error: '', userName: '', classId: '', classList: [], studentList: [], academicYears: [], academicYear: '',
    };
    handleChange = date => {
        this.setState({
            startDate: date
        });
    };
    constructor(props) {
        super(props)

    }

    componentDidMount() {
        //your code
        let userId = localStorage.getItem('userId');
        let userName = localStorage.getItem('userName');

        this.setState({ userId: userId });
        this.setState({ userName: userName });
        console.log("userId:************************" + userId);
        this.getClasses(userId);
        this.getAcademicYear(userId)
    }

    getClasses = async (userId) => {

        try {
            let token = localStorage.getItem("token");
            let pageNo = this.state.pageNo + 1;
            const headers = { 'Authorization': `Bearer ${token}` };
            let urlData = "&userId=" + userId;
            let classData = await axios.get(process.env.REACT_APP_APIURL + 'student/getClass?' + urlData, { headers });
            if (classData.data.code == 200) {
                console.log("classData.data" + JSON.stringify(classData.data));
                //let staffData = staffData.data.data;
                this.setState({ classList: classData.data.data });

            } else {
                this.setState({ error: classData.data.message });
            }
        } catch (err) {
            console.log("error:" + JSON.stringify(err.response));
            this.setState({ isOtpSent: 'N' });
            this.setState({ error: err.response.data.message });
        }

        this.setState({ isLoading: false });

    }


    getAcademicYear = async (userId) => {

        try {
            let token = localStorage.getItem("token");
            let pageNo = this.state.pageNo + 1;
            const headers = { 'Authorization': `Bearer ${token}` };
            let urlData = "&userId=" + userId;
            let academicYearData = await axios.get(process.env.REACT_APP_APIURL + 'student/getAcademicYear?' + urlData, { headers });
            if (academicYearData.data.code == 200) {
                this.setState({ academicYears: academicYearData.data.data });

            } else {
                //this.setState({ error: result.data.message });
            }
        } catch (err) {
            console.log("error:" + JSON.stringify(err.response));
            this.setState({ isOtpSent: 'N' });
            this.setState({ error: err.response.data.message });
        }

        this.setState({ isLoading: false });

    }
    getStudentFeesByClass = async (classId) => {

        try {
            let token = localStorage.getItem("token");
            if (this.state.academicYear == "") {
                this.setState({ error: "Please select academic year" })
                return false;
            }
            
            const headers = { 'Authorization': `Bearer ${token}` };
            let urlData = "&userId=" + this.state.userId + "&classId=" + classId + "&academicYear=" + this.state.academicYear;
            let studentData = await axios.get(process.env.REACT_APP_APIURL + 'fees/getStuentFeesByClass?' + urlData, { headers });
            if (studentData.data.code == 200) {
                console.log("studentData.data" + JSON.stringify(studentData.data));
                
                this.setState({ studentList: studentData.data.data });

            } else {
                //this.setState({ error: result.data.message });
            }
        } catch (err) {
            console.log("error:" + JSON.stringify(err.response));
            this.setState({ isOtpSent: 'N' });
            this.setState({ error: err.response.data.message });
        }

        this.setState({ isLoading: false });

    }

    setSelectedClass = (classId) => {
        this.setState({ classId: classId.target.value });
        this.getStudentFeesByClass(classId.target.value);
    }
    setAcademicYear = (academicYear) => {
        this.setState({ academicYear: academicYear.target.value });
    }
    render() {
        return (
            <div>


                <div className="row">
                    <div className="col-12 grid-margin">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Student Fees</h4>

                                <div className="table-responsive">
                                    <div className='error'>{this.state.error}</div>
                                    <Form.Group className="row">
                                        <label className="col-sm-12 col-form-label">Academic Year</label>
                                        <div className="col-sm-12">
                                            <select className="form-control" value={this.state.academicYear} onChange={this.setAcademicYear}>
                                                <option value={''}>Select Academic Year</option>
                                                {
                                                    this.state.academicYears.map((object, i) => {
                                                        return <option key={i} value={object['academicYear']}>{object['academicYear']}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <label className="col-sm-12 col-form-label">Class</label>
                                        <div className="col-sm-12">
                                            <select className="form-control" value={this.state.classId} onChange={this.setSelectedClass}>
                                                <option value={''}>Select Class</option>
                                                {
                                                    this.state.classList.map((object, i) => {
                                                        return <option key={i} value={object['id']}>{object['className']}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </Form.Group>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th> Sr </th>
                                                <th> Student Name </th>
                                                <th> Admition Fees </th>
                                                <th> Tution Fees</th>
                                                <th> Dress Fees </th>
                                                <th> Book Fees </th>
                                                <th> Library Fees</th>
                                                <th> Cab Fees</th>
                                                <th> Other</th>
                                                <th> Total</th>
                                                <th> Paid</th>
                                                <th> Remaining</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.studentList.map((object, i) => {
                                                    return <tr key={i + 1}>
                                                        <td>{i+1}</td>
                                                        <td>{object.studentName}</td>
                                                        <td>{object.admissionFees}</td>
                                                        <td>{object.tutionFees}</td>
                                                        <td>{object.dressFees}</td>
                                                        <td>{object.bookFees}</td>
                                                        <td>{object.libraryFees}</td>
                                                        <td>{object.cabFees}</td>
                                                        <td>{object.other}</td>
                                                        <td>{object.total}</td>
                                                        <td>{object.paid}</td>
                                                        <td>{object.pending}</td>
                                                    </tr>
                                                })
                                            }


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}
export default ViewStudentFees;