import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import axios from "axios";
import Button from "components/CustomButtons/Button.js";
export class Login extends Component {
  state = {
    userPassword: '', error: '', userName: '', admitionFees:''
  };
  constructor(prop) {
    super(prop);
    //this.super(prop);
    this.state = { userName: '', userPassword: '' };
    console.log("In Constructor");
  }

  componentDidMount() {
    console.log("In componentDidMount");
  }
  
  setUserName = (userName) => {
    
    this.setState({ userName: userName.target.value });
  }
  setUserPassword = (userPassword) => {
    this.setState({ userPassword: userPassword.target.value });
  }
  Login = async () => {
    try {
      let token ='';
      
      const headers = { 'Authorization': `Bearer ${token}` };
      let urlData = "&userName=" + this.state.userName + "&password=" + this.state.userPassword;
      const data = {
        userName: this.state.userName,
        password: this.state.userPassword
      };
      let staffData = await axios.post(process.env.REACT_APP_APIURL + 'staff/login' , data, { headers });
      if (staffData.data.code == 200) {
        
        let staffDataObj = staffData.data.data;
        this.setState({ staffData: staffData.data.data });
        let userName=staffDataObj['firstName']+" "+staffDataObj['lastName'];
        let token=staffData.data.token;
        localStorage.setItem('userId', staffDataObj['id']);
        localStorage.setItem('userName', userName);
        localStorage.setItem('type', staffDataObj['type']);
        localStorage.setItem('mobileNo', staffDataObj['mobileNo']);
        localStorage.setItem("token", token);
        //this.setState();
        window.location.href = "admin/dashboard";

      } else {
        //this.setState({ error: result.data.message });
      }
    } catch (err) {
      //console.log("error:" + JSON.stringify(err.response));
      this.setState({ isOtpSent: 'N' });
      this.setState({ error: err.response.data.message });
    }

    this.setState({ isLoading: false });

    //window.href='/dashboard';
  }
  render() {
    return (
      <div>
        <div className="d-flex align-items-center auth px-0" style={{position:'relative',top:100}}>
          <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto" style={{backgroundColor:'#868487',borderRadius:'2%',position:'relative',marginTop:'10%'}}>
              
              <div className="auth-form-light text-left py-5 px-4 px-sm-5" >
                                                
                <h4  style={{color:'white',fontWeight:'bold'}}>Login</h4>
                <Form className="pt-3">
                  <div className='error'>{this.state.error}</div>
                  <Form.Group className="d-flex search-field pt-3">                  
                    <Form.Control type="email" placeholder="Username" value={this.state.userName} className="h-auto" onChange={this.setUserName} />
                  </Form.Group>
                  <Form.Group className="d-flex search-field pt-3">
                    <Form.Control type="password" placeholder="Password" value={this.state.userPassword} className="h-auto" onChange={this.setUserPassword} />
                  </Form.Group>
                  <div className="mt-3">     
                    <Button color="primary" title="Send Fees Reminder" className="btn btn-gradient-primary mr-2"  onClick={this.Login.bind(this)}>SIGN IN</Button>
                  </div>
                  {/*<div className="my-2 d-flex justify-content-between align-items-center">
                    <div className="form-check">
                      <label className="form-check-label text-muted">
                        <input type="checkbox" className="form-check-input"/>
                        <i className="input-helper"></i>
                        Keep me signed in
                      </label>

                    </div>
                    <a href="!#" onClick={event => event.preventDefault()} className="auth-link text-black">Forgot password?</a>
                  </div>
                  <div className="mb-2">
                    <button type="button" className="btn btn-block btn-facebook auth-form-btn">
                      <i className="mdi mdi-facebook mr-2"></i>Connect using facebook
                    </button>
                  </div>
                  <div className="text-center mt-4 font-weight-light">
                    Don't have an account? <Link to="/register" className="text-primary">Create</Link>
                  </div>*/}
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Login
