import React, { Component } from 'react';

import axios from "axios";


export class StaffSalaryReport extends Component {
  state = { userId: '', staffData: [], error: '' }
  constructor(props) {
    super(props)

  }
  componentDidMount() {
    //your code
    let userId = localStorage.getItem('userId');
    this.setState({ userId: userId });
    this.getStaff(userId);
  }
  getStaff = async (userId) => {

    try {
      let token = localStorage.getItem("token");
      let pageNo = this.state.pageNo + 1;
      const headers = { 'Authorization': `Bearer ${token}` };
      let urlData = "&userId=" + userId;
      let staffData = await axios.get(process.env.REACT_APP_APIURL + 'staff/getStaff?' + urlData, { headers });
      if (staffData.data.code == 200) {
        console.log("staffData.data" + JSON.stringify(staffData.data));
        //let staffData = staffData.data.data;
        this.setState({ staffData: staffData.data.data });

      } else {
        this.setState({ error: staffData.data.message });
      }
    } catch (err) {
      console.log("error:" + JSON.stringify(err.response));
      this.setState({ error: err.response.data.message });
    }
    this.setState({ isLoading: false });
  }
  render() {
    return (
      <div>
        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Staff Salary Report</h4>

                <div className="table-responsive">
                  <div className='error'>{this.state.error}</div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Sr</th>
                        <th> Name </th>
                        <th>Type</th>
                        <th> Mobile </th>
                        <th>Email</th>
                        <th> Address</th>
                        <th> Education</th>
                        <th> Experiance</th>
                        <th> Salary</th>
                        <th> Join Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.staffData.map((object, i) => {
                          return <tr key={i + 1}>
                            <td>{i+1}</td>
                            <td>
                               {object['firstName']} {object['lastName']} </td>
                           
                            <td> {object['type']}</td>
                            <td> {object['mobileNo']} </td>
                            <td>{object['email']} </td>
                            <td>{object['address']} </td>
                            <td>{object['education']} </td>
                            <td>{object['experience']} </td>
                            <td>{object['salary']} </td>
                            <td>{object['joinDate']} </td>
                          </tr>
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}
const ListItem = (props) => {

  return (
    <li className={(props.isCompleted ? 'completed' : null)}>
      <div className="form-check">
        <label htmlFor="" className="form-check-label">
          <input className="checkbox" type="checkbox"
            checked={props.isCompleted}
            onChange={props.changed}
          /> {props.children} <i className="input-helper"></i>
        </label>
      </div>
      <i className="remove mdi mdi-close-circle-outline" onClick={props.remove}></i>
    </li>
  )
};
export default StaffSalaryReport;